$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1190px,
  xl: 1550px,
) !default;

$primary: #0085bb;
$body-bg: #f5f5f5;
$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 0.75rem;
$link-color: #000;
$btn-font-size: 14px;
$btn-padding-y: 6px;
$btn-padding-x: 28px;
$form-group-margin-bottom: 23px;
$input-color: #000;
$input-border-color: #d5d5d5;
$input-border-radius: 30px;
$input-height: 44px;
$input-padding-x: 1.2rem;
$input-border-radius-sm: 30px;
$input-height-sm: 36px;
$input-padding-x-sm: 1.2rem;
$input-focus-border-color: $input-border-color;
