.component-front-tabs-menu {
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      background-color: #eeeeee;
      margin-right: 11px;
      transition: background-color 0.2s ease-in-out;

      small {
        margin-left: 5px;
        font-size: 11px;
        font-weight: 500;

        @include media-breakpoint-down('sm') {
          font-size: 9px;
          margin-left: 0;
          margin-top: 2px;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        display: block;
        padding: 15px 30px;

        @include media-breakpoint-down('sm') {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 5px 0;
        }
      }

      &.is-active {
        background-color: #fff;
      }

      @include media-breakpoint-down('sm') {
        width: 100%;
        margin-right: 4px;
        font-size: 10px;
        white-space: nowrap;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @include media-breakpoint-down('sm') {
      justify-content: space-between;
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}
