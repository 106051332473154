.layout-front-header {
  background: #ffffff;

  .divider {
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, #3d8cbb, #62cea3);
  }

  @media all and (max-width: 1260px) {
    .md-hide {
      display: none !important;
    }
  }

  .container-fluid {
    position: relative;
    display: flex;
    align-items: center;
    height: 95px;

    @include media-breakpoint-down('md') {
      height: 75px;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .header-logo {
    display: flex;

    img {
      @include media-breakpoint-down('md') {
        height: 33px;
      }
    }
  }

  .header-info {
    display: flex;
    align-items: center;
    margin-left: 100px;
    color: #000;

    .item {
      display: inline-flex;
      align-items: center;
      color: #000;

      &:first-child img {
        margin-left: 0;
      }

      img {
        margin-left: 35px;
        margin-right: 19px;
      }
    }

    @media all and (max-width: 1500px) {
      margin-left: 40px;
    }

    @include media-breakpoint-down('md') {
      display: none;
    }
  }

  .header-items {
    align-items: center;
    display: flex;
    gap: 40px;
    justify-content: flex-end;
    text-align: right;
    width: 100%;

    .header-item {
      align-items: center;
      color: #000;
      display: flex;
      font-size: 14px;
      font-weight: 300;
      gap: 12px;

      &.logged-item {
        @media (max-width: 1500px) {
          font-size: 0;
          gap: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .user-name {
      position: relative;
      top: 2px;
      font-size: 14px;
      font-weight: 700;
      color: #fff;
      margin-left: 17px;
    }

    .btn-outline-primary {
      display: inline-flex;
      align-items: center;
      margin-left: 50px;
      padding: 5px 26px !important;
      border: 2px solid #fff !important;
      background: none !important;
      border-radius: 20px !important;
      color: #fff !important;

      img {
        margin-right: 11px;
      }
    }

    @include media-breakpoint-down('md') {
      display: none;
    }
  }

  @include media-breakpoint-down('md') {
    background: #ffffff;
  }
}
