.layout-iframe {
  padding: 5px;

  .btn {
    font-size: 12px;
  }

  .component-front-main-filter {
    margin-bottom: 10px;

    .filter-title {
      font-size: 13px !important;
    }

    .active-filter-item {
      font-size: 12px !important;
      margin-top: 2px;
    }

    .component-front-tabs-menu {
      ul {
        li {
          font-size: 12px;

          span {
            padding: 10px 15px;
          }
        }
      }
    }

    .filters-content {
      .content-item {
        .filter-column {
          width: 180px;
          font-size: 12px;

          label {
            padding-left: 0;
          }
        }
      }

      .auctions-filter-actions {
        .auctions-info {
          display: flex !important;
        }
      }

      .extended-filters .price-inputs .price-input {
        gap: unset;
        grid-gap: unset;
      }
    }
  }

  .component-front-auctions-list {

    .list-items {

      .list-item {

        .item-wrapper {
          display: flex;
          min-height: auto;
          grid-gap: 15px;
          position: relative;

          @media all and (max-width: 500px) {
            display: block;
          }

          .item-image {
            display: flex;
            width: 220px;
            height: 190px;
            flex-shrink: 0;
            overflow: hidden;
            align-items: center;
            justify-content: center;
            margin-right: 0;

            @media all and (max-width: 500px) {
              width: 100%;
              margin-bottom: 10px;
            }

            img {
              max-width: 100%;
            }

            .auction-tag {
              font-size: 10px;
              left: 5px;
              top: 5px;
            }
          }

          .item-content {
            .content-inner {
              padding: 0;

              .item-title {
                font-size: 13px;
                margin: 0;
              }

              .item-info {
                line-height: 1.8;
              }
            }
          }

          .content-middle {
            padding-bottom: 8px;

            p {
              margin-top: 10px;
              margin-bottom: 0;
            }
          }

          .content-bottom {
            padding: 5px 0;

            .item-price {
              margin: 0;
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
