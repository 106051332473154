a,
button,
input,
textarea {
  outline: 0 !important;
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.nowrap {
  white-space: nowrap !important;
}

.wrap-normal {
  white-space: normal !important;
}

.white-space-normal {
  white-space: normal !important;
}

.noresize {
  resize: none !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.word-wrap-break-word {
  word-wrap: break-word !important;
}

.text-color-white {
  color: #fff !important;
}

.text-color-gray {
  color: #7d7d7d !important;
}

.text-color-gray-2 {
  color: #727272 !important;
}

.text-color-gray-3 {
  color: #868686 !important;
}

.text-color-green {
  color: #62cea3 !important;
}

.text-color-danger {
  color: #b88282 !important;
}

.text-d-none {
  text-decoration: none !important;
}

.text-d-none-hover:hover {
  text-decoration: none !important;
}

.text-underline {
  text-decoration: underline !important;
}

.b-radius-20 {
  border-radius: 20px !important;
}

.b-0 {
  border: 0 !important;
}

.b-bottom-1 {
  border-bottom: 1px solid #e8e8e8 !important;
}

.b-bottom-0 {
  border-bottom: 0 !important;
}

.f-size-10 {
  font-size: 10px !important;
}

.f-size-12 {
  font-size: 12px !important;
}

.f-size-14 {
  font-size: 14px !important;
}

.f-size-16 {
  font-size: 16px !important;
}

.f-size-19 {
  font-size: 19px !important;
}

.f-size-20 {
  font-size: 20px !important;
}

.f-size-22 {
  font-size: 22px !important;
}

.f-size-25 {
  font-size: 25px !important;
}

.f-size-40 {
  font-size: 40px !important;
}

.f-weight-normal {
  font-weight: normal !important;
}

.f-weight-bold {
  font-weight: bold !important;
}

.f-weight-300 {
  font-weight: 300 !important;
}

.f-weight-400 {
  font-weight: 400 !important;
}

.f-weight-500 {
  font-weight: 500 !important;
}

.f-weight-600 {
  font-weight: 600 !important;
}

.f-weight-700 {
  font-weight: 700 !important;
}

.l-height-28 {
  line-height: 28px !important;
}

.w-100p {
  width: 100% !important;
}

.w-15p {
  width: 15% !important;
}

.w-30p {
  width: 30% !important;
}

.w-1 {
  width: 1px !important;
}

.w-70 {
  width: 70px !important;
}

.w-115 {
  width: 115px !important;
}

.w-135 {
  width: 135px !important;
}

.w-140 {
  width: 140px !important;
}

.w-min-150 {
  min-width: 150px !important;
}

.w-min-200 {
  min-width: 200px !important;
}

.w-min-600 {
  min-width: 600px !important;
}

.w-max-100p {
  max-width: 100% !important;
}

.w-max-70 {
  max-width: 70px !important;
}

.w-max-140 {
  max-width: 140px !important;
}

.w-max-150 {
  max-width: 150px !important;
}

.w-max-160 {
  max-width: 160px !important;
}

.w-max-200 {
  max-width: 200px !important;
}

.w-max-205 {
  max-width: 205px !important;
}

.w-max-220 {
  max-width: 220px !important;
}

.w-max-225 {
  max-width: 225px !important;
}

.w-max-315 {
  max-width: 315px !important;
}

.w-max-320 {
  max-width: 320px !important;
}

.w-max-500 {
  max-width: 500px !important;
}

.h-100p {
  height: 100% !important;
}

.h-max-none {
  max-height: none !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mr-lg-35 {
  @include media-breakpoint-up('lg') {
    margin-right: 35px !important;
  }
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.p-20 {
  padding: 20px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.flex-auto {
  flex: auto !important;
}
