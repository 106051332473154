.wysiwyg {
  padding-top: 15px;
  padding-bottom: 15px;

  .invalid {
    border: 1px solid #caa2a2 !important;
  }

  .invalid-editor {
    background-color: #fdfafa;
  }

  .wrapper {
    border-radius: 20px;
    color: #000;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d5d5d5;
  }

  .toolbar {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    margin-bottom: 0;
  }

  .editor {
    padding: 0 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    min-height: 120px;
  }
}
