.layout-header {
  background: #ffffff;

  .layout-content {
    position: relative;
    display: flex;
    align-items: center;
    height: 85px;
  }

  .divider {
    height: 5px;
    width: 100%;
    background: linear-gradient(to right, #3d8cbb, #62cea3);
  }

  .container-fluid {
    position: relative;
    display: flex;
    align-items: center;
    height: 95px;
    padding-left: 90px;
    padding-right: 90px;

    @media all and (max-width: 1600px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include media-breakpoint-down('md') {
      height: 75px;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  }

  .header-logo {
    display: flex;

    img {
      @include media-breakpoint-down('md') {
        height: 33px;
      }
    }
  }

  .header-items {
    width: 100%;
    text-align: right;

    @include media-breakpoint-down('md') {
      padding-right: 60px;
    }

    .user-name {
      position: relative;
      top: 2px;
      font-size: 14px;
      font-weight: 40;
      color: #000;
      margin-left: 17px;
    }

    .btn-outline-primary {
      display: inline-flex;
      align-items: center;
      margin-left: 50px;
      padding: 5px 26px !important;
      line-height: 1;

      img {
        margin-right: 11px;
      }
    }
  }
}
