@page {
  size: A4;
  margin: 16px;
}

@media print {
  html, body, main, header, footer, .container {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    position: relative !important;
    text-indent: 0 !important;
    clear: both !important;
    width: auto !important;
    height: auto !important;
    border: none !important;
    overflow: visible;
    margin: 0 auto 0.5% auto !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    padding: 0 !important;
    text-align: left !important;
    min-width: auto !important;
    min-height: auto !important;
    direction: ltr !important;
  }
}

.print-page {

  .print-container {
    display: none;
  }

  @media print {

    .print-container {
      display: block;
      width: 100%;
    }

    .buyer-registration-alert {
      display: none;
    }

    .component-cookie-verification {
      display: none;
    }

    .layout-front-footer {
      display: none;
    }

    .layout-front-header {
      display: none;
    }

    .component-front-top-menu {
      display: none;
    }

    .front-page-wrapper {
      display: none;
    }
  }
}
