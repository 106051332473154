.component-tabs {
  & > .component-tabs-items {
    display: flex;
    gap: 10px;
    list-style: none;
    margin: 0;
    padding: 0;

    & > .component-tabs-item {
      align-items: center;
      background: #eeeeee;
      display: flex;
      font-size: 15px;
      font-weight: 500;
      gap: 12px;
      padding: 0 26px;
      cursor: pointer;

      &:hover {
        background: #fff;
      }

      &.isActive {
        background: #fff;
      }

      @include media-breakpoint-down("md") {
        font-size: 14px;
        padding-left: 15px;
        padding-right: 15px;
      }

      & > .item-text {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        padding: 18px 0;
        @include media-breakpoint-down("md") {
          font-size: 14px;
        }
        @include media-breakpoint-down("xs") {
          font-size: 12px;
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }

      & > .item-badge {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-top: 1px;
        background: linear-gradient(219.94deg, #62CEA3 0%, #3D8CBB 110.58%);
        border-radius: 50%;
        border: 1px solid linear-gradient(219.94deg, #62CEA3 0%, #3D8CBB 110.58%);
        color: white;
        flex-shrink: 0;
        font-size: 11px;
        height: 18px;
        line-height: 1;
        text-align: center;
        width: 18px;
      }
    }

    @include media-breakpoint-down("md") {
      overflow-y: hidden;
      overflow-x: auto;
    }
  }
}
