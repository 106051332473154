.component-admin-sidebar {
  z-index: 10;
  position: absolute;
  overflow: hidden;
  width: 125px;
  height: 100%;
  transition: width 0.2s ease-in-out;
  box-shadow: 10px 0px 30px rgba(0, 0, 0, 0.02);

  &:hover {
    width: 324px;

    .sidebar-content {
      .sidebar-toggle-content {
        display: none;
      }

      .sidebar-title {
        display: inherit;
      }

      .sidebar-menu {
        li {
          a {
            .item-text {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .sidebar-content {
    position: relative;
    width: 324px;
    min-height: 100%;
    padding-top: 1px;
    background: #fff;

    .sidebar-toggle-content {
      position: absolute;
      top: 47px;
      left: 53px;
      text-align: center;
    }

    .sidebar-title {
      display: none;
      position: absolute;
      margin: 39px 20px 20px 52px;
      font-weight: 300;
      font-size: 25px;
    }

    .sidebar-menu {
      margin: 90px 0 0 0;
      padding: 0 43px 39px 52px;
      list-style: none;

      li {
        margin: 0;
        padding: 0;

        &.item-active {
          font-weight: 700;

          a {
            .icon-content {
              display: none;

              &.item-hover {
                display: inline-flex;
              }
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          position: relative;
          padding: 20px 0;
          font-size: 14px;
          font-weight: 400;
          color: #000;
          text-decoration: none;

          &:hover {
            .item-text {
              text-decoration: underline;
            }

            .icon-content {
              display: none;

              &.item-hover {
                display: inline-flex;
              }
            }
          }

          .item-text {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
          }

          .icon-content {
            display: inline-flex;
            width: 23px;
            margin-right: 20px;
            align-items: center;
            justify-content: center;

            img {
              max-width: 100%;
            }

            &.item-hover {
              display: none;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down('md') {
    display: none;
  }
}
