.component-admin-user-form {

  .admin-user-form-email-content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .form-email-content {
      width: 100%;
      max-width: 670px;
    }

    .email-update-btn {
      margin-top: 5px;
      margin-bottom: 20px;
    }
  }
}
