.component-account-documents {
  background: white;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
  padding: 30px;
  @include media-breakpoint-down('md') {
    margin-bottom: 0;
    h2 {
      font-size: 20px;
    }
  }
  @include media-breakpoint-down('xs') {
    padding: 20px 15px;
  }

  .add-document {
    display: flex;
    flex-wrap: wrap;
    padding: 14px 28px 5px 28px;
    margin: 20px 0 30px 0;
    background: #f8f8f8;

    @include media-breakpoint-down('xs') {
      padding-left: 15px;
      padding-right: 15px;
    }

    .file-preloader {
      margin: 5px 0 0 0;
      padding: 0;
    }

    .item-submit {
      margin-top: 2px;
      margin-bottom: 10px;
    }

    .add-document-wrapper {
      display: flex;
      flex-wrap: wrap;

      .document-preview {
        margin-right: 16px;
      }
    }

    .item-input {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-right: auto;

      .block-title {
        margin-bottom: 10px;
        margin-right: 19px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }

      .item-drop {
        width: 171px;
        text-align: center;
        font-weight: bold;
        margin-right: 30px;
        border: 2px dashed #62cea3;
        color: #62cea3;
        padding: 5px 20px;
        margin-top: -10px;
      }

      .item-add {
        position: relative;
        margin-bottom: 10px;
        margin-right: 40px;
        white-space: nowrap;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #62cea3;
        text-align: center;
        text-decoration-line: underline;

        .success-ico {
          position: absolute;
          top: 3px;
          right: -22px;
        }
      }

      .item-type-select {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 20px;

        @include media-breakpoint-down('xs') {
          width: 100%;
        }

        .select-label {
          margin-right: 16px;
          margin-bottom: 10px;
          white-space: nowrap;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
        }

        .select-container {
          width: 286px;
          margin-bottom: 10px;
          @include media-breakpoint-down('xs') {
            width: 100%;
          }
        }
      }
    }
  }
}
