.component-auction-process-tab {
  padding: 20px 50px;

  @include media-breakpoint-down('md') {
    padding-left: 20px;
    padding-right: 20px;
  }

  .auction-result-message p:last-child {
    margin-bottom: 0;
  }

  .prepared-auction-alert {
    font-size: 12px;

    img {
      margin-right: 15px;
    }
  }

  .tab-title {
    font-size: 20px;
    font-weight: 400;
  }

  .info-items {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;

    .item {
      font-size: 14px;
      font-weight: 400;
      margin-top: 20px;
      margin-right: 35px;

      &:last-child {
        margin-right: 0;
      }

      span {
        font-weight: 700;
      }
    }
  }

  .process-form {
    padding: 20px 35px 0 35px;
    margin-top: 25px;
    background: #f4f4f4;

    form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .item-label {
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        margin-bottom: 20px;

        &.label-value {
          margin-right: 10px;
        }
      }

      .item-input {
        max-width: 159px;
        height: 40px;
        margin-right: 8px;
        margin-bottom: 20px;
        font-size: 14px;
        font-weight: 700;
      }

      .item-currency {
        font-size: 14px;
        font-weight: 400;
        margin-right: 50px;
        margin-bottom: 20px;
      }

      .item-value {
        font-weight: bold;
        font-size: 19px;
        color: #62cea3;
        margin-right: auto;
        margin-bottom: 20px;
      }

      .btn {
        margin-bottom: 20px;
      }

      .item-buttons {
        display: flex;
        flex-wrap: wrap;

        .preemption-btn {
          margin-right: 15px;
        }

        @include media-breakpoint-down('sm') {
          & {
            flex-direction: column-reverse;
            align-items: flex-start;

            .preemption-btn {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .item-table {
    padding: 10px 0;

    table {
      thead {
        tr {
          th:first-child {
            padding-left: 0;
          }

          th:last-child {
            padding-left: 0;
          }
        }
      }

      tbody {
        tr {
          td:first-child {
            padding-left: 0;
          }

          td:last-child {
            padding-left: 0;
          }
        }
      }
    }
  }
}
