.page-admin {
  .min-vh-100 {
    min-height: calc(100vh - 72px - 85px) !important;

    @include media-breakpoint-down('md') {
      min-height: calc(100vh - 72px - 65px) !important;
    }
  }

  .vh-100 {
    height: calc(100vh - 72px - 85px) !important;

    @include media-breakpoint-down('md') {
      height: calc(100vh - 72px - 65px) !important;
    }
  }

  .admin-page-wrapper {
    position: relative;
    height: 100%;
    min-height: 700px;
  }

  .page-title-with-back-button {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    align-items: flex-start;
    @include media-breakpoint-down('md') {
      padding: 16px;
      align-items: flex-start;
    }
    @include media-breakpoint-down('sm') {
      flex-direction: column;
      align-items: start;
    }

    .back-content {
      margin-left: auto;
      @include media-breakpoint-down('sm') {
        margin-left: 0;
        margin-top: 16px;
      }
    }
  }

  .admin-page-content {
    flex: 1;
    max-width: 100%;
    padding: 45px 51px 40px 160px;

    @include media-breakpoint-down('md') {
      padding: 15px 10px 40px;
    }

    .page-title-content {
      h1 {
        align-items: center;
        display: flex;
        font-size: 26px;
        font-weight: 300;
        line-height: 1.5;
        margin-bottom: 0;
        margin-right: 25px;
        white-space: nowrap;
        @include media-breakpoint-down('md') {
          flex-direction: column;
          font-size: 20px;
          align-items: flex-start;
        }
        @include media-breakpoint-down('sm') {
          margin-right: 0;
        }

        .link-holder {
          @include media-breakpoint-down('md') {
            display: flex;
          }

          a {
            margin-left: 1rem;
            @include media-breakpoint-down('md') {
              margin-left: 0;
              margin-right: 16px;
            }
          }
        }

        .title {
          white-space: normal;
          margin-left: 8px;
          @include media-breakpoint-down('md') {
            margin-left: 0;
            margin-bottom: 16px;
          }
        }
      }
    }

    .custom-page-title-content {
      h1 {
        display: inline-block;
        font-size: 20px;
        font-weight: 300;
        line-height: 1.5;
        margin-bottom: 0;
        margin-right: 25px;
        @include media-breakpoint-down('sm') {
          margin-right: 0;
        }

        .link-holder {
          display: inline-block;
          @include media-breakpoint-down('sm') {
            display: flex;
            margin-top: 1rem;
          }

          a {
            margin-left: 1rem;
            @include media-breakpoint-down('md') {
              margin-left: 0;
              margin-right: 16px;
            }
          }
        }

        .title {
          white-space: normal;
          display: inline;
          margin-right: 1rem;
          @include media-breakpoint-down('md') {
            margin-left: 0;
            margin-bottom: 16px;
          }
        }
      }
    }

    .box-content {
      margin-bottom: 45px;
      background: #ffffff;
      box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.02);
    }
  }
}
