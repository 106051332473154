.active-auction-panels {
  & > .active-auction-panel {
    border-bottom: 1px solid #e3e3e3;

    &:last-of-type {
      border-bottom: none;
    }

    & > .auction-panel-header {
      display: flex;
      flex-direction: row;
      padding: 24px 48px;
      cursor: pointer;

      @include media-breakpoint-down('sm') {
        padding-left: 12px;
        padding-right: 12px;
      }

      &:hover > .auction-panel-title {
        opacity: 0.71;
      }

      & > .auction-panel-title {
        flex: 1;
        font-size: 22px;
      }
    }

    &.is-panel-active {
      .auction-panel-header {
        .auction-panel-title {
          opacity: 1;
        }
      }
    }

    & > .auction-panel-content {
      padding: 0 48px 24px 48px;

      @include media-breakpoint-down('sm') {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}
