.component-front-i-want-to-sell {
  margin: 0 auto;
  max-width: 1400px;
  padding: 40px 0;

  h1 {
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 22px;
    text-align: center;
    @include media-breakpoint-down('sm') {
      font-size: 20px;
    }
  }

  .item-wrapper {
    .item {
      background-color: white;
      box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.04);
      display: flex;
      height: 100%;
      padding: 50px;
      @include media-breakpoint-down('md') {
        padding: 30px;
      }
      @include media-breakpoint-down('sm') {
        flex-direction: column;
        justify-content: center;
        text-align: center;
      }
      @include media-breakpoint-down('xs') {
        padding-left: 15px;
        padding-right: 15px;
      }

      &:hover {
        background: linear-gradient(67.11deg, #0193a5 -3.76%, #62cea3 100%);

        .hover-hide {
          display: none;
        }

        .hover-show {
          display: block;
        }

        p {
          color: white;
        }

        h2 {
          color: white;
        }
      }

      .hover-show {
        display: none;
      }

      .hover-hide {
        display: block;
      }

      img {
        margin-right: 50px;
        width: 100px;
        @include media-breakpoint-down('md') {
          margin-right: 30px;
          width: 60px;
        }
        @include media-breakpoint-down('sm') {
          margin: 0 auto;
          display: block;
          margin-bottom: 16px;
        }
      }

      h2 {
        font-weight: bold;
        font-size: 26px;
        line-height: 1.2;
        @include media-breakpoint-down('md') {
          font-size: 18px;
        }
      }

      h5 {
        font-weight: bold;
        font-size: 18px;
        line-height: 1.2;
      }

      p {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        color: black;
      }
    }
  }

  .subpage-content {
    background-color: white;
    box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.04);
    padding: 50px;
    @include media-breakpoint-down('sm') {
      padding: 30px;
      h2 {
        font-size: 20px;
      }
    }
    @include media-breakpoint-down('xs') {
      padding-left: 15px;
      padding-right: 15px;
    }

    .item {
      margin: 26px 0;
    }

    .banner {
      align-items: center;
      background-image: url('../images/front/i-want-to-sell/redirect-image.png');
      background-size: cover;
      display: flex;
      justify-content: center;
      padding: 20px 0;

      p {
        color: white;
        font-size: 29px;
        font-style: normal;
        font-weight: 800;
        line-height: 1;
        margin: 0;
        @include media-breakpoint-down('sm') {
          font-size: 20px;
        }
      }
    }
  }

  .contact-us {
    display: flex;
    gap: 30px;
    margin-top: 72px;
    margin-bottom: 40px;
    @include media-breakpoint-down('md') {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .heading {
      font-size: 26px;
      font-weight: 700;
      text-align: left;
      @include media-breakpoint-down('md') {
        font-size: 20px;
      }
      @include media-breakpoint-down('xs') {
        font-size: 16px;
      }
    }

    .contact-item {
      flex: 1 0 0;
      @include media-breakpoint-down('sm') {
        &--image {
          display: none;
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    form {
      height: 100%;
      background-color: white;
      padding: 60px;
      @include media-breakpoint-down('md') {
        padding: 30px;
      }
      @include media-breakpoint-down('xs') {
        padding-left: 15px;
        padding-right: 15px;
      }

      .form-group {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .f-inline-control {
          padding-left: 0;

          input {
            max-width: 100% !important;
          }
        }

        .f-inline-label {
          flex: auto;
        }
      }

      label {
        font-size: 14px;
        width: 100%;
      }

      a {
        color: #62cea3;
        text-decoration: underline;
      }
    }
  }

  .contact-us-sidebar {
    margin-bottom: 40px;
    @include media-breakpoint-down('md') {
      margin-bottom: 20px;
    }
    @include media-breakpoint-down('sm') {
      flex-direction: column;
      margin-bottom: 0;
    }

    .heading {
      font-size: 26px;
      font-weight: 700;
      text-align: left;
      @include media-breakpoint-down('sm') {
        font-size: 20px;
      }
    }

    .contact-item {
      width: 100%;
      @include media-breakpoint-down('sm') {
        width: 100%;
      }

      img {
        width: 100%;
        @include media-breakpoint-down('sm') {
          display: none;
        }
      }
    }

    form {
      background-color: white;
      padding: 40px;
      @include media-breakpoint-down('md') {
        padding: 30px;
      }
      @include media-breakpoint-down('xs') {
        padding-left: 15px;
        padding-right: 15px;
      }

      .form-group {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .f-inline-control {
          padding-left: 0;

          input {
            max-width: 100% !important;
          }
        }

        .f-inline-label {
          flex: auto;
        }
      }

      label {
        font-size: 14px;
        width: 100%;
      }

      a {
        color: #62cea3;
        text-decoration: underline;
      }
    }
  }
}
