.disabled-action {
  cursor: default;

  img {
    filter: grayscale(1);
  }
}

.close-ico {
  margin-right: 6px;
}

.auction-document-table {
  width: 100%;

  th {
    border-bottom: 1px solid #E8E8E8;
  }

  th, td {
    padding: 10px 20px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      text-align: right;
    }
  }

  tbody > tr:nth-child(even) {
    background-color: rgba(61, 140, 197, 0.05);
  }

  .btn-download {
    color: #62CEA3;
    text-decoration: underline;
    white-space: nowrap;
    background: transparent;
    border: 2px solid transparent;
  }
}

.auction-document__name {
  display: flex;
  gap: 10px;

  .file-ico {
    flex-shrink: 0;
  }

}
