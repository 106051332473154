.invoices-form {
  .f-inline-label {
    width: 180px;
    margin-bottom: 0;
    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  }

  .small-label {
    width: 60px;
    margin-right: 0;
  }

  .unit {
    white-space: nowrap;
    margin-right: 20px;
  }

  .group-with-edit {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    @media (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .f-inline-group {
      margin-bottom: 0;
    }

    a {
      @media (max-width: 768px) {
        margin-top: 16px;
      }
      @media (max-width: 400px) {
        margin-left: 0 !important;
      }
    }
  }

  .responsive-table-content {
    .f-inline-label {
      display: none;
    }

    .f-inline-control {
      width: 140px;
      max-width: 140px;
      padding: 0;
    }

    .table-select {
      width: 140px;
      max-width: 140px;
    }
  }

  .invoice-number {
    margin-right: 85px;
    @media (max-width: 400px) {
      margin-right: 0;
    }
  }

  .paid-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .custom-checkbox {
      margin: 0 !important;
      margin-right: 16px !important;
      @media (max-width: 768px) {
        margin-bottom: 16px !important;
      }
    }

    .f-inline-label {
      width: 120px;
    }

    .f-inline-group {
      margin-bottom: 0;
    }
  }

  .form-control {
    max-width: 190px !important;
  }

  .text-area {
    max-width: 100% !important;
  }
}

.invoice-service-modal {
  .f-inline-label {
    width: 75px;
    flex: 0 0 80px;
    margin-bottom: 0;

    @include media-breakpoint-down('sm') {
      width: 100%;
      flex: 1;
    }
  }
}

.invoice-sumarry {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    p {
      margin: 0;

      &:first-child {
        margin-right: 24px;
      }
    }
  }
}

.invoice-modal-content {
  .f-inline-group {
    padding-top: 10px;

    .f-inline-label {
      flex: 1;
    }
  }
}

.invoice-exports {
  margin: 32px 0 32px 0;
  display: flex;
  flex-wrap: wrap;

  button {
    margin-right: 16px;
    margin-bottom: 16px;
  }
}

.invoice-select {
  width: 100%;
  max-width: 190px;
}
