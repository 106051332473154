.hamburger-menu {
  display: none;
  z-index: 10;
  width: 0;
  height: 0;

  @include media-breakpoint-down('md') {
    display: inline;
  }

  .hamburger-menu-ico {
    position: absolute;
    top: 30px;
    right: 27px;
    width: auto;
    cursor: pointer;
  }

  .menu-content {
    z-index: 10;
    display: none;
    position: absolute;
    width: 100%;
    top: 65px;
    left: 0;
    padding-bottom: 20px;
    overflow: hidden;
    background: #fff;

    .menu-inner {
      padding: 30px 0;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

      .sidebar-menu {
        margin: 0;
        padding: 0 43px 0 48px;
        list-style: none;

        li {
          margin: 0;
          padding: 0;

          &.item-active {
            font-weight: 700;

            a,
            a:hover {
              background: url('../images/sidebar-item-arrow-hover.svg') right center no-repeat;
            }
          }

          a {
            position: relative;
            display: block;
            padding: 13px 0;
            font-size: 14px;
            font-weight: 400;
            color: #000;
            text-decoration: none;
            background: url('../images/sidebar-item-arrow.svg') right 6px center no-repeat;

            &:hover {
              background: url('../images/sidebar-item-arrow-hover.svg') right 6px center no-repeat;
            }

            .icon-content {
              display: none;
            }
          }
        }
      }
    }
  }

  &.menu-active {
    background: #fff;

    .menu-content {
      display: block;
    }
  }
}
