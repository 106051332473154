.pages-tree {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
  .tree-node {
    min-width: 280px;
    &:not(:first-child) {
      margin-top: 10px;
    }
    .node-content {
      font-size: 12px;
      font-weight: 700;
      box-shadow: 0 2px 2px -2px;
      padding: 16px 5px 16px 10px;
      border-radius: 2px;
      border: 1px solid #bbb;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .show-more {
        padding: 4px;
        cursor: pointer;
      }
    }
  }
  .node-children {
    margin-top: 10px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}
