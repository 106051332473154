.component-front-main-filter {
  margin-bottom: 35px;

  .filters-hide {
    height: 0;
    overflow: hidden;
  }

  .toggle-content {
    @include media-breakpoint-down('md') {
      text-align: center;
    }
  }

  .preloader-content {
    z-index: 9;
    display: flex;
    justify-content: center;
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .filters-preloader {
      top: 40px;
      position: absolute;
    }
  }

  .active-filters-content {
    background: #ffffff;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-wrap: wrap;
    padding: 20px 30px;
    @include media-breakpoint-down('xs') {
      padding-left: 15px;
      padding-right: 15px;
    }

    .active-filters-items {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      gap: 9px;

      .active-filter-item {
        cursor: pointer;
        margin-top: 20px;
        background: #ffffff url('../images/front/icons/remove-filter.svg') left 13px center no-repeat;
        border-radius: 3px;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        color: #000;
        font-size: 14px;
        font-weight: normal;
        padding: 5px 20px 5px 30px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .filters-content {
    position: relative;
    background: #fff;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);

    .content-item {
      padding: 30px 30px 10px;

      @include media-breakpoint-down('xs') {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .search-result-count {
      font-size: 14px;

      @include media-breakpoint-down('md') {
        font-size: 12px;
      }
    }

    .extended-filters-content {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-in-out;

      &.is-open {
        max-height: 150px;

        @include media-breakpoint-down('xl') {
          max-height: 180px;
        }

        @include media-breakpoint-down('md') {
          max-height: inherit;
        }
      }

      @include media-breakpoint-down('md') {
        transition: none;
      }
    }

    .extended-filters {
      margin-bottom: 20px;
      margin-top: 20px;

      .filter-title {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 700;
      }

      .price-inputs {
        @include media-breakpoint-down('sm') {
          flex-direction: column;
        }

        .price-input {
          align-items: center;
          display: flex;
          gap: 28px;
          width: 50%;
          @include media-breakpoint-down('sm') {
            margin-bottom: 12px;
            gap: 10px;
            width: 100%;
          }
        }
      }

      .filter-inputs {
        align-items: center;
        display: flex;
        gap: 10px;
        padding-bottom: 16px;

        label {
          margin: 0 10px 0 0;
          font-size: 12px;
          font-weight: 400;
        }

        .input-search {
          width: 100%;

          @include media-breakpoint-down('md') {
            width: 100%;
          }
        }
      }

      @include media-breakpoint-down('md') {
        flex-direction: column;
      }
    }

    .filter-column {
      display: inline-block;
      width: calc(100% / 5);
      padding: 0;
      margin-bottom: 18px;
      font-size: 14px;
      font-weight: 400;

      .custom-checkbox {
        label {
          cursor: pointer;
          padding-left: 8px;

          span {
            margin-left: 5px;
            font-size: 10px;
            font-weight: 400;
          }
        }
      }

      @media all and (max-width: 1420px) {
        width: calc(100% / 4);
      }

      @media all and (max-width: 1220px) {
        width: calc(100% / 3);
      }

      @media all and (max-width: 1120px) {
        width: calc(100% / 2);
      }

      @media all and (max-width: 520px) {
        width: 100%;
      }
    }

    .block-separator {
      border: 0;
      border-bottom: 1px solid #e3e3e3;
      margin: 15px 0;
    }
  }

  .mobile-content {
    display: none;

    @include media-breakpoint-down('md') {
      display: block;
    }
  }

  @include media-breakpoint-down('md') {

    .mobile-toggle {
      display: none;

      &.mobile-open {
        display: block;
      }
    }
  }
}
