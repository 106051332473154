.component-front-auction-detail {
  .auction-share-buttons {
    @include media-breakpoint-down('sm') {
      .btn {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .block-alert {
    align-items: center;
    margin-top: 35px;
    padding: 11px 30px;
    background: #e7fff5;
    border: 1px solid #62cea3;
    box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);

    &::after {
      @extend .clearfix;
    }

    &.alert-tab {
      margin-top: 0;
    }

    p {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      font-weight: normal;
      font-size: 15px;
      line-height: 30px;

      img {
        margin-right: 16px;
      }

      span {
        font-weight: 700;
        font-size: 18px;

        @include media-breakpoint-down('sm') {
          display: block;
          margin-top: 1px;
          font-size: 14px;
        }
      }

      @include media-breakpoint-down('sm') {
        font-size: 14px;
      }
    }

    .info-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-down('sm') {
        flex-direction: column;
        align-items: start;
      }
    }

    .start-date {
      color: #62cea3;
      font-size: 15px;
      font-weight: bold;
      line-height: 2;
    }

    .end-date {
      color: #ff0000;
      font-size: 15px;
      font-weight: bold;
      line-height: 2;
    }

    .alert-link {
      float: right;
      margin-top: 6px;
      vertical-align: middle;
      font-size: 14px;
      line-height: 21px;
      text-decoration-line: underline;
      color: #62cea3;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-down('md') {
        display: block;
        float: inherit;
        margin-top: 5px;
        font-size: 14px;
      }
    }

    @include media-breakpoint-down('sm') {
      margin-top: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .auction-detail-content {
    position: relative;
    padding: 60px 40px;
    margin-bottom: 30px;
    background: #ffffff;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);

    .action-like {
      position: absolute;
      top: 26px;
      right: 23px;
    }

    .auction-title {
      font-weight: bold;
      font-size: 23px;
      line-height: 32px;

      @include media-breakpoint-down('sm') {
        font-size: 16px;
        line-height: 28px;
      }
    }

    .top-info {
      align-items: center;
      border-bottom: 1px solid #e3e3e3;
      border-top: 1px solid #e3e3e3;
      display: flex;
      gap: 5px 15px;
      margin-top: 32px;
      padding: 10px 0;
      @include media-breakpoint-down('sm') {
        flex-direction: column;
      }

      .price-label {
        font-size: 14px;
        font-weight: 600;
      }

      .price-value {
        color: #62cea3;
        font-size: 25px;
        font-weight: bold;
        white-space: nowrap;

        @include media-breakpoint-down('sm') {
          font-size: 22px;
        }
      }

      .auction-label {
        background: #3d8cbb;
        border-radius: 20px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 500;
        padding: 3px 18px;
        text-align: center;
      }

      .top-info__right {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 15px;
        font-size: 14px;
        font-weight: 400;
        justify-content: flex-end;
        margin-left: auto;
        @include media-breakpoint-down('sm') {
          justify-content: center;
          margin-left: 0;
          margin-top: 20px;
        }

        span {
          font-weight: 700;
        }
      }
    }

    .block-auction {
      padding-top: 35px;

      .no-image {
        padding: 50px 20px;
        width: 100%;
        background: #f5f5f5;
        text-align: center;

        img {
          max-width: 100%;
        }
      }

      .column-params {
        margin-left: auto;
      }

      .column-images {
        @include media-breakpoint-up('lg') {
          @media all and (max-width: 1460px) {
            max-width: 480px;
          }

          @media all and (max-width: 1390px) {
            max-width: 450px;
          }

          @media all and (max-width: 1330px) {
            max-width: 420px;
          }

          @media all and (max-width: 1270px) {
            max-width: 400px;
          }

          @media all and (max-width: 1220px) {
            max-width: 380px;
          }
        }
      }

      .image-items {
        position: relative;
        width: 100%;
        max-width: 1000px;

        .video-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -35px;
          margin-left: -35px;

          svg {
            width: 70px;
          }
        }

        .slick-slider,
        .slick-slider div {
          outline: 0;
        }

        .slick-arrow {
          z-index: 1;
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -44px;
          width: 25px;
          height: 49px;
          text-decoration: none;
          background: url('../images/front/auction-detail/arrow-ico.svg') left 4px top 18px no-repeat,
          url('../images/front/auction-detail/arrow-bg.svg') center center no-repeat;

          &.slick-prev {
            left: 0;
          }

          &.slick-next {
            right: 0;
            transform: rotate(180deg);
          }
        }

        .image-item {
          img {
            width: 100%;
          }
        }

        @include media-breakpoint-down('sm') {
          max-width: 100%;
        }
      }

      .auction-params {
        text-align: center;

        table {
          width: 100%;
          font-size: 14px;
          text-align: left;
          margin-bottom: 10px;

          tbody {
            tr {
              background: rgba(98, 206, 163, 0.1);

              &:nth-child(2n) {
                background: #fff;
              }

              th,
              td {
                padding: 9px 20px;
              }

              th {
                font-weight: bold;
              }

              td {
                text-align: right;
              }
            }
          }

          @include media-breakpoint-down('lg') {
            font-size: 12px;
          }
        }

        .more-btn {
          font-weight: 600;
          font-size: 14px;
          line-height: 50px;
          text-decoration-line: underline;
          color: #62cea3;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .banner-info {
        align-items: center;
        background: #ffffff;
        border: 1px solid #dddddd;
        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
        display: flex;
        margin-top: 30px;
        max-width: 100%;
        padding: 20px 35px;

        .block-ico {
          margin-right: 33px;
        }

        .block-text {
          .item-title {
            color: #000000;
            font-size: 14px;
            font-weight: bold;
            margin: 0 0 10px 0;
          }

          .item-text {
            color: #000000;
            font-size: 12px;
            font-weight: normal;
            margin: 0;
          }
        }

        @include media-breakpoint-down('sm') {
          max-width: 100%;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }

    @include media-breakpoint-down('md') {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    @include media-breakpoint-down('xs') {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .block-tabs {
    margin-bottom: 30px;

    .component-front-tabs-menu ul li {
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }

      span {
        @include media-breakpoint-down('lg') {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10px 15px;
        }
      }

      @media all and (max-width: 1330px) {
        margin-right: 10px;
        font-size: 14px;
        white-space: nowrap;
      }
    }

    .component-auction-process-tab {
      padding: 0;
    }

    .draw-wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding: 30px;
      font-size: 16px;
    }

    .draw-countdown {
      margin-top: 16px;
      font-size: 14px;

      span {
        color: #62cea3;
        font-size: 20px;
        font-weight: bold;
      }
    }

    .auction-tabs-content {
      padding: 40px;
      background: #fff;
      box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.03);

      @include media-breakpoint-down('md') {
        padding: 30px 25px;
      }

      .auction-description {
        font-size: 14px;
      }

      .auction-params {
        text-align: center;

        table {
          width: 100%;
          font-size: 14px;
          text-align: left;
          margin-bottom: 10px;

          tbody {
            tr {
              background: rgba(98, 206, 163, 0.1);

              &:nth-child(2n) {
                background: #fff;
              }

              th,
              td {
                padding: 9px 20px;
              }

              th {
                font-weight: bold;
              }

              td {
                text-align: right;
              }
            }
          }

          @include media-breakpoint-down('lg') {
            font-size: 12px;
          }
        }
      }

      .auctioneer-detail {
        & > p {
          line-height: 19px;
          font-size: 13px;
        }

        .auctioneer-name {
          font-weight: bold;
          font-size: 15px;
        }

        .link {
          color: #62cea3;
          text-decoration: underline;
        }
      }
    }
  }

  .block-bottom-box {
    .box-item {
      display: flex;
      flex-direction: column;
      height: 100%;

      .box-header {
        padding: 15px 20px 15px 40px;
        background: linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%);
        box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
        font-weight: bold;
        font-size: 20px;
        color: #ffffff;

        @include media-breakpoint-down('md') {
          font-size: 16px;
        }
        @include media-breakpoint-down('xs') {
          padding-left: 20px;
        }
      }

      .box-content {
        background: #ffffff;
        box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.03);
        flex-grow: 1;
        padding: 35px 40px;

        &.item-conditions {
          padding-bottom: 50px;

          .item-text {
            font-weight: normal;
            font-size: 14px;
            color: #000000;
          }

        }

        .item-question {
          .destination-label {
            margin-bottom: 12px;
          }

          .input-destination {
            display: flex;

            .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
              width: 8px;
              height: 8px;
              top: 5px;
              left: -21px;
              background-image: none;
              background: #62cea3;
              border-radius: 100%;
            }

            .custom-radio .custom-control-label::before {
              width: 14px;
              height: 14px;
              margin-top: 1px;
            }

            .item-input-option {
              margin-right: 30px;
              margin-bottom: 20px;

              label {
                cursor: pointer;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .form-group-item {
            display: block;
            margin-bottom: 20px;

            .col {
              padding: 0;
            }

            label {
              width: auto;
              margin-left: 5px;
              margin-bottom: 10px;
            }

            input.input-md {
              margin: 0;
              height: 40px !important;
            }

            textarea.input-md {
              margin: 0;
              height: 109px !important;
              resize: none;
              border-radius: 20px;
            }
          }

          .btn {
            margin-top: 10px;
          }
        }

        @include media-breakpoint-down('xs') {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }

  .more-info {
    margin-bottom: 16px;

    a {
      font-weight: bold;
      font-size: 14px;
      color: #62cea3;
    }
  }

  .auction-progress {
    margin-top: 40px;
    border-left: 2px solid #b8b8b8;
    margin-left: 20px;

    .progress-item {
      position: relative;
      margin-bottom: 55px;
      left: -21px;
      @include media-breakpoint-down('sm') {
        margin-bottom: 30px;
      }

      &::after {
        @extend .clearfix;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .progress-number {
        float: left;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        line-height: 23px;
        width: 40px;
        height: 40px;
        margin-right: 30px;
        background: #fff;
        border: 2px solid #aeaeae;
        box-sizing: border-box;
        border-radius: 100%;
        font-weight: 600;
        font-size: 19px;
        color: #a4a4a4;

        @include media-breakpoint-down('md') {
          margin-right: 20px;
        }
      }

      .progress-text {
        padding-top: 6px;
        font-weight: 400;
        font-size: 18px;
        color: #585858;

        @include media-breakpoint-down('md') {
          padding-top: 9px;
          font-size: 16px;
        }
      }

      &.item-active {
        .progress-number {
          background: #2fa023;
          border: 2px solid #2fa023;
          color: #fff;
        }

        .progress-text {
          font-weight: bold;
          color: #000000;
        }
      }
    }
  }
}
