input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.filters-checkbox {
  margin-top: 9px;
}

.f-inline-group {
  align-items: center;
  display: flex;
  gap: 10px 20px;
  margin-bottom: 30px;
  @include media-breakpoint-down('sm') {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }

  .f-inline-control {
    width: 100%;
    margin-right: 10px;
  }

  .col {
    @include media-breakpoint-down('sm') {
      padding: 0;
    }
  }

  &.f-align-start {
    align-items: flex-start;
  }

  .f-inline-label {
    flex: 0 0 130px;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    width: 130px;
    @include media-breakpoint-down('sm') {
      flex: 0 1 auto;
      text-align: left !important;
      width: 100%;
    }
  }

  .form-control {
    @include media-breakpoint-down('sm') {
      max-width: 100% !important;
      width: 100%;
    }
  }
}

.help-icon-control {
  float: left;
}

.help-icon-value {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 1px;
  right: 51px;
  width: 50px;
  height: 42px;
  background: rgba(0, 0, 0, 0.05);
  border-left: 1px solid #d5d5d5;
  border-radius: 0 30px 30px 0;
  font-size: 15px;
}

.resize-none {
  resize: none;
}

.mt-checkbox {
  margin-top: 7px !important;
}

.checkbox-margin {
  margin-top: -8px;
}

.label-right label {
  width: 100%;
  text-align: right;
}

.inline-label {
  display: flex;
  align-items: center;

  label {
    white-space: nowrap;
    margin: 0 10px 0 0;
  }
}

.input-ico {
  position: relative;

  input {
    padding-right: 40px !important;
  }

  .ico {
    position: absolute;
    right: 17px;
    bottom: 10px;
  }

  &.input-ico-md .ico {
    bottom: 14px;
  }
}

.form-check-label {
  position: relative;
  top: 2px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #d3d3d3 !important;
  background-color: #fff;
  box-shadow: none !important;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
  border-color: #d3d3d3 !important;
  background-color: #fff !important;
  box-shadow: none !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url('../images/icon-checked-checkbox.svg');
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url('../images/icon-checked-checkbox.svg');
}

.custom-control-label::after {
  position: absolute;
  top: 0.0625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-radio.radio-point label {
  cursor: pointer;
}

.custom-radio.radio-point .custom-control-input:checked ~ .custom-control-label::after {
  width: 8px;
  height: 8px;
  top: 5px;
  left: -21px;
  background-image: none;
  background: #62cea3;
  border-radius: 100%;
}

.custom-radio.radio-point .custom-control-label::before {
  width: 14px;
  height: 14px;
  margin-top: 1px;
}

.form-group .react-date-picker {
  display: inline-flex;
  padding: 0 10px 0 15px;
  align-items: center;

  input {
    padding-right: inherit !important;
  }

  .react-date-picker__wrapper {
    border: 0;
  }
}

textarea.form-control {
  padding-top: 15px;
  padding-bottom: 15px;
}

.form-control {
  &.input-xs {
    padding-bottom: 2px;
    height: 30px !important;
  }

  &.input-md {
    padding-bottom: 5px;
    height: 36px !important;
  }
}

.hide-number-spin {
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-control {
  &:focus {
    border: 1px solid #000;
  }

  &.is-invalid {
    background: #fdfafa;
    border: 1px solid #caa2a2;
  }
}

.invalid-feedback.custom {
  width: auto;
  position: absolute;
  display: inline-flex !important;
  margin-top: 5px;
  color: #b88282;
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  white-space: nowrap;

  img {
    margin-right: 10px;
  }
}

.custom-switch {
  .custom-control-label {
    cursor: pointer;
    margin-top: -4px;

    &::before {
      padding: 0;
      width: 51px !important;
      height: 26px !important;
      outline: 0 !important;
      border-radius: 20px !important;
      border: 0 !important;
      background: linear-gradient(267.59deg, #62cea3 3.8%, #3d8cbb 89.2%) !important;
      box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.15) !important;
      filter: grayscale(100%);
    }

    &::after {
      width: 17px;
      height: 17px;
      margin-top: 2px;
      margin-left: 3px;
      background: #ffffff !important;
      box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25) !important;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    filter: grayscale(0%);
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateX(calc(24px));
  }
}

.datetime-feedback .invalid-feedback {
  margin-top: 75px;
}

.react-datepicker__header {
  h2 {
    font-size: 1rem !important;
    font-weight: 700 !important;
  }
}

.react-datepicker-popper {
  z-index: 100;
}

.jodit-container:not(.jodit_inline).jodit_disabled {
  background: #e9ecef !important;

  .jodit-workplace {
    opacity: 1 !important;
  }
}

.file-item-drop {
  width: 171px;
  text-align: center;
  font-weight: bold;
  border: 2px dashed #62cea3;
  color: #62cea3;
  padding: 5px 20px;
  margin-top: -10px;
}
