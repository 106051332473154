.component-admin-auctions-table {
  max-width: 100%;
  overflow: auto;

  .table tbody tr td {
    width: inherit;
  }

  .table tbody tr td.auction-column-title {
    max-width: 300px;
    min-width: 150px;
    white-space: normal;
  }
}
