.component-map {
  .map {
    width: 100%;
    .leaflet-popup {
      bottom: 32px !important;
      padding-top: 40px;
    }
    .leaflet-popup-content-wrapper {
      padding: 0;
      overflow: inherit;
      background: none;
      box-shadow: none;
      border: 0;
    }

    .leaflet-popup-content {
      padding: 0;
      overflow: inherit;
      background: transparent;
      margin: 0;
    }

    .leaflet-popup-content img {
      filter: none;
    }

    /* Optional overflow handling with scroll and background */
    .leaflet-popup-content.card-overflow {
      overflow: auto;
      background: white;
      max-height: 400px;
      margin-right: 15px;
    }

    /* Table inside popup */
    .leaflet-popup-content table tbody tr td {
      border: 1px solid #dfdfdf;
    }

    .leaflet-popup-tip-container {
      border: 0;
      padding: 0;
      background: none;
    }

    /* Close button customization */
    .leaflet-popup-close-button {
      opacity: 1;
      top: 50px;
      right: -7px;
      width: 18px !important;
      height: 18px !important;
      color: transparent !important;
      background: url('../images/front/map/close-ico.svg') center center no-repeat,
        url('../images/front/map/close-bg.svg') center center no-repeat !important;
    }

    .leaflet-popup-close-button:after {
      display: none;
      content: '';
    }

    svg,
    img {
      cursor: default;
    }

    &.bw {
      .leaflet-tile-pane {
        filter: grayscale(100%) brightness(112%);
      }
      .leaflet-marker-icon {
        cursor: pointer;
      }
      .cluster {
        & > div {
          vertical-align: middle;

          img {
            display: none;
          }
        }
      }

      .marker {
        img {
          filter: none;
        }

        &.marker-pointer img {
          cursor: pointer;
        }
      }
    }

    .map-div-icon {
      padding: 3px;
      background: url('../images/front/map/cluster-border.svg');

      .map-cluster {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background: linear-gradient(44.03deg, #3d8cbb -12.71%, #62cea3 110.24%);
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 50px;
        color: #fff;
      }
    }

    /* Cluster marker styles */
    .marker-cluster {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 500;
      margin-top: 0;
      margin-left: 0;
    }

    /* Specific styles for different sizes of clusters */
    .marker-cluster-small {
      background-image: url('../images/front/map/cluster-border.svg'); /* Custom background image */
      background-color: transparent;
      width: 40px;
      height: 40px;
    }

    .marker-cluster-small div {
      width: 34px;
      height: 34px;
    }

    .marker-cluster-small span {
      line-height: 34px;
    }

    .marker-cluster-medium {
      background-image: url('../images/front/map/cluster-border.svg'); /* Custom background image */
      background-color: transparent;
      width: 40px;
      height: 20px;
    }

    .marker-cluster-medium div {
      width: 34px;
      height: 34px;
    }

    .marker-cluster-small span {
      line-height: 34px;
    }

    .marker-cluster-large {
      background-image: url('../images/front/map/cluster-border.svg'); /* Custom background image */
      background-color: transparent;
      margin: 4px;
    }

    .marker-cluster-large div {
      width: 34px;
      height: 34px;
    }

    .marker-cluster-large span {
      line-height: 34px;
    }

    .marker-cluster div {
      background: linear-gradient(44.03deg, #3d8cbb -12.71%, #62cea3 110.24%);
      border-radius: 50%;
      margin-left: 0;
      margin-top: 0;
    }

    .marker-cluster span {
      margin: 0; /* Remove default margin */
      line-height: 34px;
    }

    /* Additional hover effect (optional) */
    .marker-cluster:hover {
      transform: scale(1.1); /* Slightly enlarge on hover */
      transition: transform 0.2s; /* Smooth transition */
    }
  }

  .map-popup-content {
    width: 279px;
    padding: 12px;
    margin-top: 20px;
    background: #fff;
    box-shadow: 15px 15px 40px rgba(0, 0, 0, 0.2) !important;

    &.width-lg {
      width: 500px;
      margin-top: 0;
      box-shadow: none !important;
    }
  }

  .popup-image-content {
    text-align: center;
  }

  .map-popup-title {
    margin-top: 10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .map-popup-text {
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    strong {
      font-weight: 700;
    }
  }

  .map-popup-link {
    white-space: nowrap;
    transition: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    padding: 0;
    background: transparent;
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline;
    color: #62cea3;

    &:hover {
      text-decoration: none;
    }
  }
}
