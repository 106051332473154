.layout-front-footer {
  background: linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%);
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);
  color: #fff;
  padding-top: 45px;

  @include media-breakpoint-down('xs') {
    padding-top: 20px;
  }

  .footer-block {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 35px;
    line-height: 2;
    padding: 15px 0;

    @include media-breakpoint-down('md') {
      padding-left: 10px;
      padding-right: 10px;
    }

    p {
      margin: 0;
    }
  }

  .footer-menu {
    margin: 2px 0 0 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;
      font-size: 14px;
      font-weight: 400;

      a {
        display: block;
        color: #fff;
        padding: 6px 0 6px 20px;
        background: url('../images/front/icons/arrow-right-white-6.svg') left center no-repeat;
      }
    }
  }


  .copyright {
    background: linear-gradient(89.75deg, rgba(0, 0, 0, 0.2) -2.08%, rgba(63, 63, 63, 0.2) 102.31%);
    margin-top: 35px;
    padding: 18px 10px;
    text-align: center;
    @include media-breakpoint-down('sm') {
      margin-top: 20px;
    }
  }

  .footer__contact {
    color: #fff;
    display: flex;
    font-size: 30px;
    font-weight: bold;
    gap: 15px;
    margin-bottom: 10px;
    white-space: nowrap;

    &--email {
      font-size: 24px;
    }

    @include media-breakpoint-down('md') {
      font-size: 24px;
      &--email {
        font-size: 20px;
      }
    }
  }

  .footer-social-sites {
    display: flex;
    gap: 30px;

    a {
      opacity: 0.3;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .footer-social-text {
    font-weight: bold;
    margin-bottom: 25px;
    @include media-breakpoint-down('sm') {
      margin-bottom: 15px;
    }
  }
}
