.component-informed-consents-modal {
  max-width: 1024px;

  .modal-item-content {
    padding: 30px;
  }

  label {
    text-align: left;
    cursor: pointer;
    padding-left: 20px;
    margin-left: 3px;

    p {
      margin-top: -2px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .items-row {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
