.page-admin-buyer {
  .step-buyer-section {
    margin-top: 50px;

    .section-title {
      font-weight: 300;
      font-size: 20px;
      margin-bottom: 40px;
    }
    .autoComplete_wrapper {
      width: 100%;
    }
    #user-address {
      width: 100%;
      height: 44px;
      border: 1px solid;
      border-color: #d5d5d5;
      border-radius: 30px;
      font-size: 12px;
      padding-left: 20px;
      padding-right: 20px;
      color: #000;
      &:focus {
        border-color: #000;
      }
    }
    #user-address-delivery {
      width: 100%;
      height: 44px;
      border: 1px solid;
      border-color: #d5d5d5;
      border-radius: 30px;
      font-size: 12px;
      padding-left: 20px;
      padding-right: 20px;
      color: #000;
      &:focus {
        border-color: #000;
      }
    }
    #user-address-invoice {
      width: 100%;
      height: 44px;
      border: 1px solid;
      border-color: #d5d5d5;
      border-radius: 30px;
      font-size: 12px;
      padding-left: 20px;
      padding-right: 20px;
      color: #000;
      &:focus {
        border-color: #000;
      }
    }
    .autoComplete_wrapper mark {
      color: #000;
      font-size: 14px;
      padding: 0;
    }
    .autoComplete_wrapper li {
      color: #888;
      font-size: 12px;
      padding: 6px 20px;
    }
  }

  .section-address .step-buyer-section:first-child {
    margin-top: -50px;
  }

  .invoice-address-checkbox {
    margin-top: 10px;
  }
}
