.front-hamburger-menu {
  display: none;
  z-index: 15;
  width: 0;
  height: 0;

  @include media-breakpoint-down('md') {
    display: inline;
  }

  .hamburger-menu-ico {
    position: absolute;
    top: 31px;
    right: 27px;
    width: auto;
    cursor: pointer;

    span {
      background: linear-gradient(#62cea3, #3d8cbb);
    }
  }

  .menu-content {
    z-index: 10;
    display: none;
    position: absolute;
    width: 100%;
    top: 75px;
    left: 0;
    padding-bottom: 20px;
    overflow: hidden;
    background: #fff;

    .menu-inner {
      padding: 20px 30px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);

      .block-separator {
        height: 1px;
        margin: 10px 0;
        background: #e3e3e3;
      }

      .block-bottom {
        .block-item {
          display: flex;
          align-items: center;
          margin-top: 20px;
          font-size: 12px;
          font-weight: 500;

          span {
            display: inline-block;
            width: 22px;
            margin-right: 15px;
            text-align: center;
          }
        }
      }

      .mobile-menu-items {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin: 0;
            padding: 0;

            &.item-active {
              a,
              a:hover {
                background: url('../images/sidebar-item-arrow-hover.svg') right center no-repeat;
                font-weight: 700;
              }
            }

            a {
              position: relative;
              display: block;
              padding: 12px 0;
              font-size: 12px;
              font-weight: 400;
              color: #000;
              text-decoration: none;
              background: url('../images/front/mobile/menu-item.svg') right 6px center no-repeat;

              &:hover {
                background: url('../images/front/mobile/menu-item-active.svg') right 6px center no-repeat;
              }

              span {
                font-size: 10px;
                margin-left: 5px;
              }
            }
          }
        }
      }

      .mobile-logged-user {
        ul {
          margin: 25px 0 10px 0;
          padding: 0;
          list-style: none;

          li {
            margin: 0;
            padding: 0;

            .main-ico {
              position: relative;
              top: -2px;
              left: -2px;
              margin-left: 6px;
              margin-right: 15px;
            }

            a {
              display: flex;
              align-items: center;
              font-size: 12px;
              font-weight: 700;
              text-decoration: none;
              background: url('../images/front/mobile/menu-item.svg') right 6px center no-repeat;

              &:hover {
                background: url('../images/front/mobile/menu-item-active.svg') right 6px center no-repeat;
              }
            }

            ul {
              display: none;
              margin: 0 0 0 38px;

              li {
                margin: 18px 0;

                a {
                  font-weight: 400;

                  img {
                    margin-right: 9px;
                  }
                }

                &.no-arrow a {
                  background: none;
                }
              }
            }

            &:hover,
            &:active,
            &:focus {
              ul {
                display: inherit;
              }
            }

            &.item-active {
              a {
                font-weight: 700;
                background: url('../images/front/mobile/menu-item-active.svg') right 6px center no-repeat;
              }
            }
          }
        }
      }
    }
  }

  &.menu-active {
    background: #fff;

    .menu-content {
      display: block;
    }
  }
}
