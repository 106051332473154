.component-front-banner-navigation {

  .navigation-item {
    align-items: center;
    background: #fff;
    box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.04);
    display: flex;
    gap: 30px;
    height: 100%;
    padding: 30px;
    @include media-breakpoint-down('md') {
      gap: 20px;
      padding-left: 15px;
      padding-right: 15px;
    }
    @include media-breakpoint-down('sm') {
      flex-direction: column;
      text-align: center;
    }

    .item-ico {
      align-items: center;
      display: flex;
      flex: 0 1 90px;
      height: 90px;
      justify-content: center;
      width: 90px;

      img {
        max-width: 100%;
      }

      @include media-breakpoint-down('md') {
        flex-basis: 50px;
        height: 50px;
        width: 50px;
      }
    }

    .item-text {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      height: 100%;
      justify-content: flex-end;
      @include media-breakpoint-down('md') {
        font-size: 12px;
      }
    }

    .item-heading {
      font-size: 25px;
      font-weight: 600;
      @include media-breakpoint-down('md') {
        font-size: 20px;
      }
      @include media-breakpoint-down('sm') {
        font-size: 16px;
      }
    }

    .hover-show {
      display: none;
    }

    .btn.btn-custom.btn-outline-primary {
      transition: none;

      &:hover {
        color: #000 !important;
        background: #fff !important;
      }
    }

    &:hover,
    &.item-active {
      background: linear-gradient(67.11deg, #0193a5 -3.76%, #62cea3 100%);

      .hover-show {
        display: inline-flex;
      }

      .hover-hide {
        display: none;
      }

      .item-text {
        color: #fff;
      }

      .btn {
        background: #fff !important;
      }
    }
  }

  @include media-breakpoint-down('md') {
    margin-bottom: 10px;
  }
}
