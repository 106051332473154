.component-box-centered {
  width: 100%;
  max-width: 571px;
  background: #fff;

  .box-header {
    padding: 14px 39px 18px 39px;
    background: linear-gradient(89.89deg, #62cea3 -57.26%, #3d8cbb 172.86%);

    @include media-breakpoint-down('md') {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .box-content {
    padding: 45px;
    box-shadow: 10px 4px 30px rgba(0, 0, 0, 0.03);

    @include media-breakpoint-down('md') {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
