.news-page-title {
  color: #000000;
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 28px;
  margin-top: 12px;
  word-wrap: break-word;

  @include media-breakpoint-down('md') {
    margin-bottom: 38px;
    text-align: center;
  }

  @include media-breakpoint-down('sm') {
    margin-top: 0;
    margin-bottom: 25px;
    font-size: 20px;
  }

  &::first-letter {
    text-transform: capitalize;
  }
}

.news-item {
  align-items: center;
  background: white;
  display: flex;
  gap: 20px;
  margin-bottom: 32px;
  padding: 15px;
  @include media-breakpoint-down('md') {
    gap: 0;
  }
  @include media-breakpoint-down('sm') {
    flex-direction: column;
    text-align: center;
  }

  .news-title {
    font-size: 19px;
    font-weight: bold;
    color: black !important;
    text-decoration: none !important;
  }

  .news-perex {
    font-size: 14px;
    font-weight: normal;

    * {
      font-family: 'Poppins' !important;
    }
  }

  .news-read-more {
    font-size: 14px;
    color: #62cea3;
    font-weight: bold;
  }

  .news-item-text {
    padding: 30px;
    @include media-breakpoint-down('sm') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .news-item-image-holder {
    width: 422px;
    min-width: 422px;
    @include media-breakpoint-down('sm') {
      width: 100%;
      min-width: 1px;
    }

    img {
      width: 100%;
    }
  }
}

.component-front-news-detail {
  background: white;
  padding: 35px;
  @include media-breakpoint-down('md') {
    padding: 16px;
  }

  .news-title {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  .news-detail-image-holder {
    width: 100%;
    margin-bottom: 32px;

    img {
      width: 100%;
    }
  }

  .news-content {
    font-size: 16px;

    * {
      font-family: 'Poppins' !important;
    }
  }

  .image-items {
    position: relative;
    width: 100%;
    max-width: calc(100vw - 590px);
    margin: 0 auto;

    @media all and (max-width: 1600px) {
      max-width: calc(100vw - 450px);
    }

    @media all and (max-width: 1200px) {
      max-width: 100%;
    }

    .video-icon {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -35px;
      margin-left: -35px;

      svg {
        width: 70px;
      }
    }

    .slick-slider,
    .slick-slider div {
      outline: 0;
    }

    .slick-arrow {
      z-index: 1;
      display: block;
      position: absolute;
      top: 50%;
      margin-top: -44px;
      width: 25px;
      height: 49px;
      text-decoration: none;
      background: url('../images/front/auction-detail/arrow-ico.svg') left 4px top 18px no-repeat,
      url('../images/front/auction-detail/arrow-bg.svg') center center no-repeat;

      &.slick-prev {
        left: 0;
      }

      &.slick-next {
        right: 0;
        transform: rotate(180deg);
      }
    }

    .image-item {
      img {
        width: 100%;
      }
    }

    @include media-breakpoint-down('sm') {
      max-width: 100%;
    }
  }
}

.related-title {
  font-size: 26px;
  font-weight: 400;
  margin: 38px 0;

  @include media-breakpoint-down('sm') {
    font-size: 20px;
    text-align: center;
  }
}
