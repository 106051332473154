.layout-footer {
  min-height: 72px;
  background: #414141;
  color: #fff;

  .container-fluid {
    padding: 20px 57px;

    @include media-breakpoint-down('md') {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
