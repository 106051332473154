.component-front-top-menu {
  background: #fff;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);

  ul {
    float: left;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      font-size: 15px;
      font-weight: 500;
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }

      a {
        display: block;
        position: relative;
        padding: 25px 0 17px 0;
        text-decoration: none;

        &:after {
          content: ' ';
          display: block;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          opacity: 0;
          background: linear-gradient(270deg, #62cea3 -3.06%, #3d8cbb 101.19%);
          transition: opacity 0.2s ease-in-out;
        }
      }

      &.is-active a:after,
      &:hover a:after {
        opacity: 1;
      }

      @include media-breakpoint-down('md') {
        white-space: nowrap;
      }
    }

    @include media-breakpoint-down('md') {
      display: none;
    }
  }

  .current-time {
    position: relative;
    top: 1px;
    float: right;
    padding: 14px 0;
    margin-right: -5px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 36px;
    color: #000000;

    span {
      display: inline-block;
      min-width: 165px;
      color: #62cda3;
      font-weight: 700;

      @include media-breakpoint-down('md') {
        width: 130px;
        margin-left: 5px;
      }
    }

    @include media-breakpoint-down('md') {
      display: flex;
      justify-content: center;
      float: inherit;
      font-size: 12px;
      padding: 8px 0;
    }
  }
}
