.account-page {
  & > .account-page-title {
    font-size: 20px;
    margin-bottom: 20px;
    @include media-breakpoint-down('md') {
      text-align: center;
    }
  }

  & > .account-state-block {
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);
    margin-bottom: 36px;
    @include media-breakpoint-down('sm') {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &.isActive {
      & > .block-index {
        background: -webkit-linear-gradient(180deg, #62cea3 0%, #3d8cbb 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      & > .block-action {
        font-weight: 600;
        font-size: 20px;
        line-height: 50px;
      }
    }

    & > .block-index {
      color: #e2e2e2;
      font-size: 70px;
      line-height: 100px;
      margin: auto 0;
      text-align: center;
      width: 150px;
      @include media-breakpoint-down('md') {
        font-size: 50px;
        width: 120px;
      }
    }

    & > .block-content {
      border-left: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
      flex: 1;
      padding: 48px;
      @include media-breakpoint-down('md') {
        padding: 25px;
      }
      @include media-breakpoint-down('sm') {
        border: none;
        padding: 0 15px 24px;
      }

      & > .block-content-title {
        font-size: 20px;
        margin-bottom: 15px;
      }

      & > .block-content-text {
        font-size: 12px;
        line-height: 21px;
      }
    }

    & > .block-action {
      margin: auto 0;
      text-align: center;
      width: 218px;
      @include media-breakpoint-down('sm') {
        padding-bottom: 24px;
      }
    }
  }

  .auctions-empty {
    align-items: center;
    background: #ffffff;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);
    display: flex;
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
    gap: 20px;
    padding: 30px;
    @include media-breakpoint-down('xs') {
      font-size: 14px;
      padding: 15px;
    }
  }
}

.change-password-success {
  margin-top: 16px;
  color: #62cea3;
  font-size: 14px;
  font-weight: bold;
}
