.page-account-auction-informations {
  .component-front-account-box {
    padding: 20px 0;
  }

  .draw-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px;
    font-size: 16px;
  }

  .draw-countdown {
    margin-top: 16px;
    font-size: 14px;

    span {
      color: #62cea3;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .items {
    .item {
      display: flex;
      align-items: flex-start;
      padding: 20px 20px 20px 70px;
      border-bottom: 1px solid #e3e3e3;

      @include media-breakpoint-down('md') {
        padding-left: 20px;
      }

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .item-number {
        width: 95px;
        margin: 0;
        padding: 0;
        text-align: left;
        font-style: normal;
        font-weight: 600;
        font-size: 70px;
        color: #e2e2e2;

        @include media-breakpoint-down('md') {
          margin-top: 10px;
          font-size: 40px;
          width: auto;
          margin-right: 20px;
        }
      }

      .item-value {
        padding-top: 22px;

        .item-title {
          margin-bottom: 12px;
          font-style: normal;
          font-weight: normal;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.6);
        }

        .item-text {
          p {
            font-style: normal;
            font-size: 12px;
            line-height: 21px;
            color: rgba(0, 0, 0, 0.6);

            strong {
              font-weight: 600;
            }
          }
        }
      }

      &.item-active {
        .item-number {
          color: #2fa023;
          font-weight: 400;
        }

        .item-title {
          color: #000;
        }

        .item-text p {
          color: #000;
        }
      }
    }
  }

  .block-alert {
    align-items: center;
    margin-top: 13px;
    padding: 11px 30px;
    background: #e7fff5;
    border: 1px solid #62cea3;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.04);

    &::after {
      @extend .clearfix;
    }

    p {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      font-weight: normal;
      font-size: 15px;
      line-height: 30px;

      img {
        margin-right: 16px;
      }

      span {
        font-weight: 700;
        font-size: 18px;

        @include media-breakpoint-down('sm') {
          display: block;
          margin-top: 1px;
          font-size: 14px;
        }
      }

      @include media-breakpoint-down('sm') {
        font-size: 14px;
      }
    }

    .info-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include media-breakpoint-down('sm') {
        flex-direction: column;
        align-items: start;
      }
    }

    .start-date {
      font-weight: bold;
      font-size: 15px;
      line-height: 30px;
      color: #62cea3;
    }

    .end-date {
      font-weight: bold;
      font-size: 15px;
      line-height: 30px;
      color: #ff0000;
    }

    .alert-link {
      float: right;
      margin-top: 6px;
      vertical-align: middle;
      font-size: 14px;
      line-height: 21px;
      text-decoration-line: underline;
      color: #62cea3;

      &:hover {
        text-decoration: none;
      }

      @include media-breakpoint-down('md') {
        display: block;
        float: inherit;
        margin-top: 5px;
        font-size: 14px;
      }
    }

    @include media-breakpoint-down('sm') {
      margin-top: 20px;
    }
  }
}
