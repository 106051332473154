.table {
  border-bottom: 1px solid #dfdfdf;

  thead tr th {
    border: 0;

    &.checkbox-column {
      position: relative;
      padding-left: 55px;

      .custom-checkbox {
        position: absolute;
        left: 16px;
      }
    }

    &.stc {
      position: relative;
      cursor: pointer !important;

      &.active {
        font-style: italic;
      }

      &:hover {
        color: #666;
        cursor: default;
      }

      &.stc::after {
        margin-left: 0;
        position: absolute;
        display: inline-block;
        content: url('../images/table/sort-neutral.png');
        bottom: 4px;
      }

      &.ASC::after {
        position: absolute;
        display: inline-block;
        content: url('../images/table/sort-up.png');
        bottom: 4px;
      }

      &.DESC::after {
        position: absolute;
        display: inline-block;
        content: url('../images/table/sort-down.png');
        bottom: 4px;
      }
    }
  }

  tbody tr td {
    white-space: nowrap;
    width: 1%;
    padding-top: 18px;
    padding-bottom: 18px;
    border: 0;

    &.checkbox-column {
      position: relative;
      padding-left: 55px;

      .custom-checkbox {
        position: absolute;
        left: 16px;
      }
    }
  }

  &.table-middle {
    thead tr th,
    tbody tr td {
      vertical-align: middle;
    }
  }

  &.table-striped {
    tbody tr {
      background: none;

      &:nth-child(2n) {
        background: rgba(60, 135, 180, 0.12);
      }
    }
  }

  .border-top {
    border-top: 1px solid #e3e3e3;
  }
}

.auction-date-error {
  bottom: -5px;
  position: absolute;

  .custom.invalid-feedback {
    margin-top: 0;
  }
}

.responsive-table-content {
  overflow-x: auto;
  padding-top: 1px;

  .auction-time-picker {
    display: flex;
    align-items: center;
  }

  @include media-breakpoint-down('xs') {
    input {
      max-width: 100% !important;
    }
    .f-inline-label,
    .custom-control-label {
      text-align: left !important;
    }
    .form-group {
      margin-left: 0 !important;
      flex-direction: column;
      align-items: flex-start !important;

      .col {
        padding-left: 0;
      }
    }
    .auction-datetime-picker {
      flex-direction: column;
      align-items: flex-start !important;
    }
    .auction-time-picker {
      display: flex;
      align-items: center;
      margin-top: 30px;

      .time-at {
        display: none;
      }
    }
  }

  @include media-breakpoint-down('md') {
    table {
      white-space: nowrap;
    }
  }
}
