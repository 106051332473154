.component-password-validator {
  margin-top: 10px;

  .component-items {

    .component-item {
      display: inline-block;
      background: $success;
      color: white;
      margin-right: 10px;
      border-radius: 10px;
      padding: 3px 8px;

      &:last-child {
        margin-right: 0;
      }

      &.item-danger {
        background: $danger;
      }

      &.item-warning {
        background: #e0ad1b;
      }
    }
  }
}
