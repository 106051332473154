.registration-steps {
  background-color: white;
  padding: 30px;
  @include media-breakpoint-down('xs') {
    padding: 15px;
  }

  & > .step-items {
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-bottom: 48px;
    padding-bottom: 30px;
    @include media-breakpoint-down('xs') {
      flex-basis: 10px;
      margin-bottom: 25px;
    }

    & > .step-divider {
      border-top: 1px solid #e3e3e3;
      flex: 0 0 100px;
      padding-bottom: 36px;
      @include media-breakpoint-down('xs') {
        flex-basis: 75px;
      }
    }

    & > .step-item {
      cursor: default;
      display: inline-block;
      text-align: center;
      width: auto;

      & > .step-item-title {
        font-size: 12px;
        margin-top: 12px;
      }
    }
  }

  & > .step-email {
    .registration-text {
      margin-top: 30px;
      font-size: 12px;
      line-height: 21px;
    }

    .account-type-wrapper {
      display: flex;
      gap: 15px;
      margin-top: 30px;
      @include media-breakpoint-down('sm') {
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
    }

    label {
      width: auto;
    }
  }

  & > .step-buyer {
    margin-top: 15px;

    & > .step-buyer-section {
      &.invoice-address-checkbox {
        margin-top: -25px !important;
        margin-bottom: 0px;
      }

      &.invoice-address-content {
        margin-bottom: 15px;
      }

      & > .section-title {
        padding-bottom: 15px;
        margin-bottom: 30px;
        border-bottom: 1px solid #e3e3e3;
        font-size: 20px;
        line-height: 50px;

        &.noBorder {
          margin-bottom: 0;
          border-bottom: none;
          font-size: 26px;
          line-height: 50px;
        }
      }

      & .user-type-options {
        display: flex;
        flex-direction: row;
        flex-flow: wrap;

        & > .user-type-option {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 220px;
          max-width: 280px;
          height: 52px;
          margin-bottom: 15px;
          margin-right: 15px;
          border-radius: 5px;
          font-weight: 600;
          font-size: 14px;
          cursor: pointer;
          border: 1px solid #e6e6e6;

          &:hover {
            border-color: transparent;
            background: linear-gradient(82.64deg, rgba(61, 140, 187, 0.11) -4.3%, rgba(98, 206, 163, 0.11) 100%);
          }

          &.isActive {
            color: white;
            border: 0;
            background: linear-gradient(82.64deg, #3d8cbb -4.3%, #62cea3 100%);
            box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
          }

          @include media-breakpoint-down('lg') {
            min-width: 200px;
          }
          @include media-breakpoint-down('sm') {
            width: 100%;
          }
        }

        @include media-breakpoint-down('sm') {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .autoComplete_wrapper {
        width: 100%;
        max-width: 500px !important;
      }
      #user-address {
        width: 100%;
        height: 44px;
        border: 1px solid;
        border-color: #d5d5d5;
        border-radius: 30px;
        font-size: 12px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
      }
      #user-address:focus {
        border-color: #000;
      }
      #user-address-delivery {
        width: 100%;
        height: 44px;
        border: 1px solid;
        border-color: #d5d5d5;
        border-radius: 30px;
        font-size: 12px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        &:focus {
          border-color: #000;
        }
      }
      #user-address-invoice {
        width: 100%;
        height: 44px;
        border: 1px solid;
        border-color: #d5d5d5;
        border-radius: 30px;
        font-size: 12px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        &:focus {
          border-color: #000;
        }
      }
      [id^='user-additional-person-address-'] {
        width: 100%;
        height: 44px;
        border: 1px solid;
        border-color: #d5d5d5;
        border-radius: 30px;
        font-size: 12px;
        padding-left: 20px;
        padding-right: 20px;

        &:focus {
          border-color: #000;
        }
      }
      .autoComplete_wrapper mark {
        color: #000;
        font-size: 14px;
        padding: 0;
      }
      .autoComplete_wrapper li {
        color: #888;
        font-size: 12px;
        padding: 6px 20px;
      }
    }
  }

  & > .step-finished {
    align-items: center;
    display: flex;
    gap: 50px;
    @include media-breakpoint-down('md') {
      gap: 30px;
    }
    @include media-breakpoint-down('xs') {
      flex-direction: column;
    }

    & > .step-finished-image img {
      max-width: 100%;
      @include media-breakpoint-down('xs') {
        height: 50px;
      }
    }

    & > .step-finished-content {
      font-size: 12px;
      line-height: 21px;

      & > .title {
        font-size: 26px;
        margin-bottom: 25px;
        @include media-breakpoint-down('sm') {
          font-size: 20px;
        }
      }
    }
  }

  .terms {
    margin: 0;

    a {
      color: #62cda3;
      font-weight: bold;
    }
  }
}
