.swal2-title {
  background: linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%) !important;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04) !important;
  align-items: flex-start !important;
  padding: 16px 15px 14px 45px !important;
  margin: 0 !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 27px !important;
  color: #ffffff !important;
}

.swal2-popup {
  padding: 0 !important;
}

.swal2-html-container {
  padding: 25px 15px 14px 45px !important;
  text-align: left !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 18px !important;
  color: #000000 !important;
}

.swal2-confirm {
  white-space: nowrap !important;
  height: 34px !important;
  transition: none !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 5px 25px !important;
  border-radius: 30px !important;
  border: solid 2px transparent !important;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
  linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 130.87%) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
  box-shadow: 2px 1000px 1px #fff inset !important;
  text-decoration: none !important;
  color: #000 !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 21px !important;

  &:hover {
    color: #fff !important;
    box-shadow: none !important;
    background-image: linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%) !important;
    background-clip: unset !important;
  }
}

.swal2-cancel {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 18px !important;
  text-decoration-line: underline !important;
  color: #000000 !important;
  background: none !important;
}

.swal2-actions {
  padding: 10px 15px 35px 45px !important;
  margin: 0 !important;
  justify-content: flex-start !important;
}
