.ReactModal__Body--open {
  overflow: hidden;
}

.modal-content {
  display: block;
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 437px;
  padding: 0;
  background-color: #fff;
  border-radius: 0;
  outline: none;
  border: 0;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.04);

  &.modal-content-md {
    max-width: 760px;
  }

  &.modal-content-lg {
    max-width: 1280px;
  }

  .modal-header {
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: 15px 20px 15px 45px;
    background: linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%);
    justify-content: space-between;
    p {
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-size: 20px;
      color: #fff;
    }

    span {
      cursor: pointer;
      display: inline-block;
    }

    @include media-breakpoint-down('md') {
      padding-left: 30px;
      padding-right: 30px;
    }
    @include media-breakpoint-down('xs') {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .modal-item-content {
    padding: 25px 50px;

    @include media-breakpoint-down('md') {
      padding: 30px;
    }
    @include media-breakpoint-down('xs') {
      padding-bottom: 15px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &.modal-no-padding {
    .modal-item-content {
      padding: 0;
    }
  }
}

.modal-overlay {
  z-index: 1000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
}
