.component-front-hint {
  margin: 0 auto;
  max-width: 1400px;
  padding: 40px 0;

  h1 {
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 38px;
    text-align: center;
    @include media-breakpoint-down('sm') {
      font-size: 20px;
    }
  }

  .main-wrapper {
    display: grid;
    grid-auto-rows: 1fr;
    grid-gap: 16px;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 36px;
    margin-top: 36px;
    @include media-breakpoint-down('md') {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-breakpoint-down('xs') {
      grid-template-columns: repeat(2, 1fr);
    }

    .main-item-wrapper {
      .item {
        align-items: center;
        background-color: white;
        box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.04);
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 38px;
        height: 100%;
        padding: 40px;
        text-align: center;
        @include media-breakpoint-down('lg') {
          gap: 15px;
          padding: 30px 15px;
          img {
            height: 50px;
          }
        }
        @include media-breakpoint-down('xs') {
          img {
            height: 40px;
          }
        }

        &:hover {
          background: linear-gradient(67.11deg, #0193a5 -3.76%, #62cea3 100%);

          .hover-hide {
            display: none;
          }

          .hover-show {
            display: block;
          }

          p {
            color: white;
          }

          h5 {
            color: white;
          }
        }

        .hover-show {
          display: none;
        }

        .hover-hide {
          display: block;
        }

        h5 {
          font-weight: bold;
          font-size: 16px;
          line-height: 1.5;
          color: black;
          margin: 0;
          @include media-breakpoint-down('sm') {
            font-size: 14px;
          }
        }

        p {
          font-size: 16px;
          line-height: 1.4;
          color: black;
        }
      }
    }
  }

  .hint-wrapper {
    margin-top: 18px;
    margin-bottom: 36px;

    .hint-content {
      padding: 48px 50px;
      background: white;
      @include media-breakpoint-down('md') {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .action-holder {
      border-top: 1px solid #dbdbdb;
      padding: 16px 50px;
      background: white;
      @include media-breakpoint-down('md') {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}
