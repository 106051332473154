.component-base-front-sidebar-box {
  width: 100%;
  margin-bottom: 25px;
  box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.04);
  overflow: hidden;

  .box-header {
    background: linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 30px;
    @include media-breakpoint-down('xs') {
      padding: 15px;
    }
  }

  .box-content {
    background: #fff;
    padding: 30px;
    @include media-breakpoint-down('xs') {
      padding: 15px;
    }
  }

  &.sidebar-notifications .box-content {
    background: #fff url('../images/front/sidebar/bell-bg.svg') right -22px bottom -21px no-repeat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    padding: 30px;
    @include media-breakpoint-down('xs') {
      padding: 15px;
    }
  }

  &.sidebar-statistics {
    .box-content {
      @include media-breakpoint-down('xs') {
        padding-left: 15px;
        padding-right: 15px;
      }

      .statistics-row {
        display: flex;
        margin-bottom: 18px;
        align-items: center;

        .statistics-icon .icon-content {
          text-align: left;
          width: 45px;
        }

        .statistics-text {
          font-size: 14px;
          line-height: 24px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .sidebar-menu {
    ul {
      width: 100%;
      min-height: 205px;
      margin: -10px 0 -10px 0;
      padding: 0;
      list-style: none;

      li {
        width: 100%;
        margin: 0;
        padding: 0;

        a {
          display: block;
          padding: 10px 0;
          font-size: 14px;
          font-weight: 400;
          text-decoration: none;
          background: url('../images/front/sidebar/menu-item.svg') right center no-repeat;

          span {
            margin-left: 5px;
            font-size: 10px;
            font-weight: 400;
          }
        }

        &:hover a,
        &.is-active a {
          font-weight: 700;
          background: url('../images/front/sidebar/menu-item-active.svg') right center no-repeat;
        }
      }
    }
  }

  .box-images {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    text-align: center;
    @include media-breakpoint-down('md') {
      flex-direction: row;
    }
    @include media-breakpoint-down('xs') {
      gap: 15px;
    }

    img {
      max-width: 100%;
    }
  }
}
