.zoom-toggle {
  display: none;
}

@media all and (min-width: 1190px) and (max-width: 1820px) {
  .zoom-enabled {
    height: 0;
    transform: scale(.75);
    transform-origin: 0 0;
    width: calc(100% / 0.75);

    .admin-page-wrapper {
      min-height: calc(125vh - 91px) !important;
    }

    .auction-actions {
      z-index: 1 !important;
      position: relative !important;

      .panel-left {
        margin-left: 0 !important;
      }

      .panel-right {
        padding-right: 20px !important;
      }
    }
  }

  .zoom-toggle {
    display: inline-flex;

    .action-label {
      margin-top: 2px;
      margin-left: 25px;
      margin-right: 25px;
    }
  }
}
