.auction-preview-container {
  padding: 24px;

  @include media-breakpoint-down('sm') {
    padding-left: 0;
    padding-right: 0;
  }

  & > .auction-preview {
    display: flex;
    flex-direction: row;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);

    @include media-breakpoint-down('md') {
      flex-wrap: wrap;
    }

    & > .auction-preview-image {
      position: relative;
      width: 100%;
      max-width: 450px;
      margin: 13px;

      @include media-breakpoint-down('md') {
        max-width: 100%;
      }

      & > img {
        width: 100%;
      }

      & > .auction-preview-status {
        position: absolute;
        top: 18px;
        left: 16px;
        color: #fff;
        border-radius: 50px;
        padding: 5px 18px;
        background: linear-gradient(268.2deg, #62cea3 0%, #3d8cbb 88.63%);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
      }

      &.no-image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        background: #f5f5f5;

        img {
          max-width: 160px;
        }
      }
    }

    & > .auction-preview-content {
      flex: 1;
      padding: 32px 0 32px 32px;
      min-width: 320px;
      padding-right: 20px;

      .actions-buttons {
        .btn {
          margin-top: 10px;
        }
      }

      @include media-breakpoint-down('sm') {
        padding-left: 12px;
        padding-right: 12px;
      }

      & > .preview-content-title {
        margin-bottom: 20px;
        font-size: 19px;
        font-weight: 700;
        line-height: 27px;

        @include media-breakpoint-down('md') {
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
    }

    & > .auction-preview-prices {
      width: auto;
      min-width: 200px;
      padding: 48px 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-left: 1px solid #e3e3e3;

      @include media-breakpoint-down('sm') {
        border-left: none;
        border-top: 1px solid #e3e3e3;
        width: 100%;
      }

      & > .preview-prices-price {
        display: flex;
        flex-direction: column;

        &.blue > .price-value {
          color: #3d8cbb;
        }

        &.green > .price-value {
          color: #62cea3;
        }

        & > .price-title {
          font-size: 14px;
          line-height: 29px;
          text-align: center;
        }

        & > .price-value {
          font-weight: bold;
          font-size: 25px;
          line-height: 27px;
          text-align: center;
        }
      }
    }
  }
}
