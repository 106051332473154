.buyer-auction-container {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  & > .buyer-auction-action {
    position: absolute;
    top: 24px;
    right: 24px;
    transform: translate(50%, -50%);
    cursor: pointer;

    &:hover svg {
      fill: #A02323 !important;
    }
  }

  & > .buyer-auction-content {
    display: flex;
    flex-direction: row;
    padding: 12px;
    background-color: white;

    & > .buyer-auction-image {
      min-width: 120px;
      max-width: 420px;

      & > img {
        width: 100%;
      }
    }

    & > .buyer-auction {
      flex: 1;
      margin-left: 32px;

      & > .buyer-auction-title {
        font-weight: bold;
        font-size: 19px;
        line-height: 27px;
        margin-right: 48px;
      }

      & > .buyer-auction-item {
        font-size: 14px;
        line-height: 29px;

        & > .buyer-auction-item-label {
          margin-right: 8px;
          font-weight: bold;
        }
      }

      & > .buyer-auction-timeframe {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        line-height: 50px;

        & > .buyer-auction-timeframe-start,
        & > .buyer-auction-timeframe-end {
          flex: 1;
        }
      }

      & > .buyer-auction-prices {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-top: 12px;
        border-top: 1px solid #E3E3E3;

        & > .buyer-auction-price {
          font-weight: bold;
          font-size: 25px;
          line-height: 25px;
          color: #62CEA3;
        }

        & > .buyer-auction-guess-price {
          display: inline-flex;
          flex-direction: column;
          font-weight: bold;
          font-size: 16px;
          line-height: 32px;
          color: #3D8CBB;

          & > .buyer-auction-guess-price-label {
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: #000000;
          }
        }

        & > .buyer-auction-more-info {
        }
      }
    }
  }

  & > .buyer-auction-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    padding: 24px 0;

    & > .buyer-auction-footer-step {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      color: #AEAEAE;

      &.isActive {
        & > .step-index {
          background-color: #2FA023;
          border-color: #2FA023;
          color: white;
        }

        & > .step-text {
          color: black;
        }
      }

      & > .step-index {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        margin-left: 24px;
        border: 2px solid #AEAEAE;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }

      & > .step-text {
        margin-left: 12px;
      }
    }
  }
}
