.about-us-page-title {
  color: #000000;
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 28px;
  margin-top: 12px;
  word-wrap: break-word;

  &::first-letter {
    text-transform: capitalize;
  }

  @include media-breakpoint-down('md') {
    margin-bottom: 38px;
    text-align: center;
  }

  @include media-breakpoint-down('sm') {
    font-size: 20px;
  }
}

.component-about-us {
  background: white;
  padding: 35px;
  @include media-breakpoint-down('md') {
    padding: 16px;
  }

  .about-us-content {
    font-size: 16px;

    * {
      font-family: 'Poppins' !important;
    }
  }
}

.related-title {
  font-size: 26px;
  font-weight: 400;
  margin: 38px 0;
}
