.auction-not-found {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
  text-align: center;

  &__btn {
    white-space: nowrap;
    height: 34px;
    transition: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 25px !important;
    border-radius: 30px !important;
    border: solid 2px transparent !important;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(
        89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 130.87%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    box-shadow: 2px 1000px 1px #f5f5f5 inset !important;
    text-decoration: none !important;
    font-size: 14px;

    &:hover {
      color: #fff !important;
      box-shadow: none !important;
      background-image: linear-gradient(
          89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%) !important;
      background-clip: unset !important;
    }
  }

  .headline {
    color: #EBEBEB;
    font-weight: bold;
    font-size: 15em;
    text-shadow: -1px -1px 7px rgba(150, 150, 150, 1);
    margin: auto;
  }

  .info {
    font-size: 26px;
    line-height: 50px;
    margin: auto;
    color: #222;

    &__small {
      margin: 0 auto 30px auto;
      font-size: 16px;
      line-height: 27px;
      color: #222;
    }
  }
}
