.bids-alert-box {
  align-items: center;
  padding: 11px 30px;
  margin-bottom: 25px;
  background: #FFFFE7;
  border: 1px solid #CFC462;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.04);

  &::after {
    @extend .clearfix;
  }

  p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;

    img {
      margin-right: 16px;
    }

    span {
      font-weight: 700;
      font-size: 18px;

      @include media-breakpoint-down('sm') {
        display: block;
        margin-top: 1px;
        font-size: 14px;
      }
    }

    @include media-breakpoint-down('sm') {
      font-size: 14px;
    }
  }

  @include media-breakpoint-down('sm') {
    margin-top: 20px;
  }
}
