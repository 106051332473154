.pagination {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 10px 0;

  li {
    vertical-align: middle;
    font-size: 15px;
    line-height: 1;

    a {
      display: block;
      outline: none;
    }

    &.next {
      margin-left: 5px;
    }

    &.previous {
      margin-right: 5px;
    }

    &.next a,
    &.previous a {
      align-items: center;
      background-clip: content-box, border-box !important;
      background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 130.87%) !important;
      background-origin: border-box !important;
      background: none;
      border-radius: 100%;
      border: solid 2px transparent !important;
      box-shadow: 2px 1000px 1px #fff inset !important;
      color: $primary;
      display: flex;
      justify-content: center;
      min-height: 30px;
      min-width: 30px;
      padding: 5px;
      text-align: center;

      &:hover {
        background-clip: unset !important;
        background-image: linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%) !important;
        box-shadow: none !important;
        color: #fff !important;
      }
    }

    &.selected {
      a {
        min-width: 30px;
        min-height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        text-align: center;
        color: $primary;
        background: none;
        border: solid 2px transparent !important;
        border-radius: 100%;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
        linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 130.87%) !important;
        background-origin: border-box !important;
        background-clip: content-box, border-box !important;
        box-shadow: 2px 1000px 1px #fff inset !important;
      }
    }
  }
}
