.component-admin-filters-box {
  display: flex;

  @include media-breakpoint-down('md') {
    &.block-open {
      .content-inputs {
        display: inherit;
      }

      .filters-submit {
        border-top: 1px solid #dfdfdf;

        .submit-button {
          display: inline-flex !important;
        }

        .open-button {
          display: none !important;
        }
      }
    }
  }

  @include media-breakpoint-down('md') {
    display: block;
  }

  .content-inputs {
    flex: 1;
    padding: 25px 30px 10px;

    @include media-breakpoint-down('md') {
      display: none;
    }

    @include media-breakpoint-down('xs') {
      padding-left: 15px;
      padding-right: 15px;
    }

    label {
      margin-left: 5px;
    }
  }

  .filters-submit {
    align-items: center;
    border-left: 1px solid #dfdfdf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 174px;

    .clear-btn {
      margin-top: 10px;
    }

    .btn {
      margin: 0 auto;

      @include media-breakpoint-down('md') {
        margin: 0;
      }

      img {
        margin-right: 10px;
      }
    }

    .open-button {
      display: none !important;
      justify-content: center;
    }

    @include media-breakpoint-down('md') {
      border-left: 0;
      border-top: 0;
      flex-direction: row;
      justify-content: space-between;
      padding: 25px;
      width: inherit;

      .clear-btn {
        margin-top: 0;
      }

      .open-button {
        display: inline-flex !important;
      }

      .submit-button {
        display: none !important;
      }
    }
  }
}

.react-datepicker-wrapper {
  display: block;

  input {
    background: url('../images/input-ico-calendar.svg') no-repeat right 10px center;
    padding-right: 30px;
  }
  .react-datepicker__header {
    h2 {
      font-size: 1rem !important;
      font-weight: 700 !important;
    }
  }
}
