.component-front-account-box {
  padding: 30px;
  background: #ffffff;
  box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);

  @include media-breakpoint-down('xs') {
    padding: 20px 15px;
  }

  .box-title {
    color: #000000;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
  }
}
