.component-front-auction-information {

  .information-title {
    font-size: 20px;
    font-weight: 400;
  }

  .information-item {
    margin-top: 20px;
  }
}
