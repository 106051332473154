.btn {
  .hover-show {
    display: none;
  }

  &:hover {
    .hover-hide {
      display: none;
    }

    .hover-show {
      display: inline-flex;
    }
  }

  &.btn-outline-default {
    box-shadow: none !important;
    background: none !important;
    color: #000 !important;
    outline: 0 !important;
    border: 2px solid #e4e4e4 !important;
    border-radius: 20px !important;

    &:hover {
      text-decoration: underline;
    }
  }

  &.btn-link {
    box-shadow: none !important;
    background: none !important;
    color: #62cea3 !important;
    font-weight: bold;
    outline: 0 !important;
    border: 0 !important;
    background: #fff !important;
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &.btn-white {
    box-shadow: none !important;
    background: none !important;
    color: #000 !important;
    outline: 0 !important;
    border: 0 !important;
    border-radius: 20px;
    background: #fff !important;

    &:hover {
      text-decoration: underline;
    }
  }

  &.btn-outline-primary {
    background-position: center !important;
    background-size: 100% 100% !important;
    background: none !important;
    border: 0 !important;
    box-shadow: none !important;
    color: #000 !important;
    outline: 0 !important;

    &.btn-custom {
      &.btn-outline-primary {
        align-items: center;
        background-clip: content-box, border-box !important;
        background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 130.87%) !important;
        background-origin: border-box !important;
        border-radius: 30px !important;
        border: solid 2px transparent !important;
        box-shadow: 2px 1000px 1px #fff inset !important;
        display: inline-flex;
        height: 34px;
        justify-content: center;
        padding: 5px 25px;
        text-decoration: none !important;
        transition: none !important;
        white-space: nowrap;

        &.btn-small {
          font-size: 12px;
          padding-left: 20px;
          padding-right: 20px;
        }

        &.btn-gray-bg {
          box-shadow: 2px 1000px 1px #f5f5f5 inset !important;
        }

        &:hover,
        &.is-active {
          color: #fff !important;
          box-shadow: none !important;
          background-image: linear-gradient(89.78deg, #0092a5 -0.41%, #0092a5 29.25%, #62cea3 100.87%) !important;
          background-clip: unset !important;

          .hover-hide {
            display: none;
          }

          .hover-show {
            display: inline-flex;
          }
        }
      }
    }
  }

  &.btn-outline-grey {
    white-space: nowrap;
    height: 34px;
    transition: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 25px !important;
    border-radius: 30px !important;
    // border: solid 2px transparent !important;
    border: 2px solid #c7c7c7;
    // background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    //   linear-gradient(89.78deg, #C7C7C7 -0.41%, #C7C7C7 29.25%, #C7C7C7 130.87%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    box-shadow: 2px 1000px 1px #fff inset !important;
    text-decoration: none !important;
    color: #c7c7c7;

    &:hover {
      color: #b5b5b5 !important;
      box-shadow: none !important;
      border: 2px solid #b5b5b5;
      // background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      //   linear-gradient(89.78deg, #B5B5B5 -0.41%, #B5B5B5 29.25%, #B5B5B5 130.87%) !important;
      background-clip: unset !important;
    }
  }

  &.btn-none {
    white-space: nowrap;
    height: 34px;
    transition: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 25px !important;
    border-radius: 30px !important;
    // border: solid 2px transparent !important;
    border: 2px solid transparent;
    // background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    //   linear-gradient(89.78deg, #C7C7C7 -0.41%, #C7C7C7 29.25%, #C7C7C7 130.87%) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    box-shadow: 2px 1000px 1px transparent inset !important;
    text-decoration: none !important;
    // color: #c7c7c7;

    &:hover {
      color: #b5b5b5 !important;
      box-shadow: none !important;
      border: 2px solid transparent;
      // background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
      //   linear-gradient(89.78deg, #B5B5B5 -0.41%, #B5B5B5 29.25%, #B5B5B5 130.87%) !important;
      background-clip: unset !important;
    }
  }
}
