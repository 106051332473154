.component-admin-box-content {
  padding: 30px;

  h2 {
    font-size: 26px;
    font-weight: 300;
    @include media-breakpoint-down('md') {
      font-size: 20px;
    }
  }

  @include media-breakpoint-down('xs') {
    padding-left: 15px;
    padding-right: 15px;
  }
}
