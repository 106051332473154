.action-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;

  .checkbox-wrapper {
    display: flex;

    p {
      margin-left: 14px;
    }
  }

  @include media-breakpoint-down('sm') {
    display: block;

    button {
      margin-top: 20px;
    }
  }
}

.settings-group {
  @include media-breakpoint-down('sm') {
    flex-direction: column;
    align-items: flex-start !important;
    .custom-radio,
    .form-group {
      margin-top: 16px;
    }
  }
}

.input-with-checkbox {
  @include media-breakpoint-down('md') {
    flex-direction: column;
    align-items: start !important;
  }

  .f-inline-group {
    @include media-breakpoint-down('sm') {
      width: 100%;
    }
  }

  .custom-checkbox {
    @include media-breakpoint-down('md') {
      margin-top: 16px;
    }
  }

  :first-of-type {
    input {
      width: 500px;
      @include media-breakpoint-down('sm') {
        width: 100%;
      }
    }
  }
}

.bottom-action-holder {
  display: flex;
  justify-content: flex-end;

  button {
    margin-left: 50px;
  }
}

.back-link {
  color: #62cea3;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: #62cea3;
  }
}

.auction-export-table-wrapper {
  padding: 35px;
  margin-bottom: 30px;
  background-color: white;

  .export-content-title {
    display: flex;

    .item-title {
      font-size: 25px;

      @include media-breakpoint-down('sm') {
        font-size: 20px;
      }
    }
  }

  .content-title {
    font-size: 25px;
    margin-bottom: 24px;

    @include media-breakpoint-down('sm') {
      font-size: 20px;
    }
  }

  @include media-breakpoint-down('sm') {
    padding-left: 20px;
    padding-right: 20px;
  }
}
