.auctioneers-page-title {
  color: #000000;
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 37px;
  margin-top: 12px;
  word-wrap: break-word;

  &::first-letter {
    text-transform: capitalize;
  }

  @include media-breakpoint-down('md') {
    text-align: center;
  }

  @include media-breakpoint-down('sm') {
    font-size: 20px;
    margin-bottom: 0;
  }
}

.front-auctioneer-filter {
  margin: 15px;

  .extended-filters {
    align-items: center;
  }

  .filter-input {
    align-items: center;
    display: flex;
    gap: 8px;
    width: 100%;
    @include media-breakpoint-down('sm') {
      flex-direction: column;
    }

    img {
      width: 40px;
      min-width: 40px;
      height: 40px;
      cursor: pointer;
    }

    .form-label {
      margin: 0;
      font-size: 14px;
      white-space: nowrap;
      @include media-breakpoint-down('sm') {
        margin-top: 16px;
      }
    }

    .select-input,
    .input-search {
      margin-bottom: 0;
      width: 100%;
    }

    .search-button {
      border: none;
      background: none;
      @include media-breakpoint-down('sm') {
        margin-top: 16px;
      }
    }
  }
}

.component-front-auctioneers-list {
  margin-top: 40px;

  .block-title {
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 400;

    @include media-breakpoint-down('md') {
      font-size: 20px;
    }
  }

  .item-action {
    align-items: center;
    border-left: 1px solid #e3e3e3;
    display: flex;
    justify-content: center;
    padding: 30px;
    @include media-breakpoint-down('md') {
      border-left: 0;
      border-top: 1px solid #e3e3e3;
    }
    @include media-breakpoint-down('xs') {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .info {
    display: flex;
    gap: 10px;
    @include media-breakpoint-down('sm') {
      flex-direction: column;
    }

    .info-content {
      flex: 1 0 0;
    }
  }

  .item-content {
    display: flex;
    @include media-breakpoint-down('md') {
      flex-direction: column;
    }

    p {
      font-size: 14px;
      margin-bottom: 8px;

      a {
        color: #62cea3;
        text-decoration: underline;
      }
    }

    .strong {
      font-weight: 700;
    }
  }

  .item-info {
    flex: 1;
    padding: 35px 30px;
    @include media-breakpoint-down('md') {
      padding-bottom: 20px;
      padding-top: 30px;
    }
    @include media-breakpoint-down('xs') {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .item-title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 24px;
    @include media-breakpoint-down('sm') {
      font-size: 20px;
    }
  }

  .list-item {
    background: #fff;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
    position: relative;
  }
}

.auctioneers__box {
  background-color: #fff;
  font-weight: 800;
  padding: 30px;
  @include media-breakpoint-down('xs') {
    padding-left: 15px;
    padding-right: 15px;
  }
}
