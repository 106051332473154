.admin-services {
  overflow-x: visible;

  .f-inline-label {
    width: 150px;
  }

  .multiline-input {
    margin-top: 16px;
    display: flex;
    @include media-breakpoint-down('sm') {
      flex-direction: column;
    }

    .main-label {
      width: 150px !important;
      min-width: 150px;
      @include media-breakpoint-down('sm') {
        font-weight: bold;
        margin-bottom: 16px;
      }
    }

    .f-inline-label {
      width: 60px;
      margin-right: 10px;
      @include media-breakpoint-down('sm') {
        margin-top: 16px;
      }
    }

    .f-inline-group {
      margin-bottom: 0;
    }
  }
}

.admin-service-link {
  color: #62cea3;
  cursor: pointer;
  font-weight: bold;
}

.service-detail-modal-parent {
  max-width: 600px !important;
}

.service-detail-modal {
  h5 {
    margin: 16px 0;
  }

  p {
    margin-bottom: 4px;
  }

  .items-wrapper {
    .items-holder {
      display: flex;

      p {
        &:first-child {
          flex: 1;
        }

        width: 21%;
      }
    }
  }
}
