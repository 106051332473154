.buyer-registration-alert {
  padding: 6px 0;
  background-color: black;
  color: white;
  text-align: center;

  & > a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }
}

.page-front {
  .container-fluid {
    padding-left: 90px;
    padding-right: 90px;

    @media all and (max-width: 1600px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include media-breakpoint-down('md') {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .min-vh-100 {
    min-height: calc(100vh - 95px) !important;
  }

  .vh-100 {
    height: calc(100vh - 95px) !important;
  }

  .front-page-wrapper {
    padding: 28px 0;

    .front-page-sidebar {
      width: 100%;
      max-width: 303px;

      @include media-breakpoint-down('md') {
        max-width: inherit;
      }
    }

    .front-page-content {
      width: 100%;
    }

    .front-main-container {
      gap: 30px;

      @include media-breakpoint-down('md') {
        flex-direction: column-reverse;
      }

      @include media-breakpoint-down('sm') {
        gap: 0;
      }
    }
  }

  @include media-breakpoint-down('md') {
    overflow-x: hidden;
  }

  .auctions-empty {
    align-items: center;
    background: #ffffff;
    box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);
    color: #000000;
    display: flex;
    font-size: 17px;
    gap: 20px;
    padding: 20px 30px;
    margin-bottom: 20px;

    @include media-breakpoint-down('md') {
      font-size: 15px;
    }
    @include media-breakpoint-down('xs') {
      padding: 15px;
    }
  }
}
