.component-front-contact {
  margin: 0 auto;
  max-width: 1400px;
  padding-top: 12px;

  h1 {
    color: black;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 38px;
    text-align: center;
    @include media-breakpoint-down('sm') {
      font-size: 20px;
    }
  }

  h2 {
    color: black;
    font-size: 26px;
    font-weight: 400;
    margin-bottom: 38px;
    text-align: center;
    @include media-breakpoint-down('md') {
      font-size: 20px;
    }
  }

  .contact-card-wrapper {
    height: 100%;
  }

  .contact-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    padding: 40px;
    height: 100%;
    @include media-breakpoint-down('md') {
      padding: 20px 15px;
    }

    .icon-holder {
      height: 86px;
      @include media-breakpoint-down('md') {
        height: 60px;
        img {
          height: 50px;
        }
      }
      @include media-breakpoint-down('sm') {
        height: 40px;
        img {
          height: 30px;
        }
      }
    }

    p {
      font-size: 14px;
      color: black;
      margin-bottom: 4px;
      text-align: center;
    }

    a {
      font-size: 16px;
      color: #62cea3;
      font-weight: 700;
      text-decoration: underline;
    }

    .strong-text {
      font-size: 16px;
      font-weight: 700;
    }

    .span-text {
      font-size: 12px;
      color: #5c5c5c;
      margin-top: 16px;
    }
  }

  .component-map {
    height: 100%;
  }

  .map {
    height: 100% !important;
    min-height: 400px;
  }

  .our-team {
    margin-top: 16px;

    .our-team-item {
      align-items: center;
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 1.5rem;
      padding: 32px;
      @include media-breakpoint-down('md') {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    img {
      border-radius: 50%;
      width: 120px;
      height: 120px;
    }

    .name {
      font-size: 16px;
      font-weight: 700;
      margin-top: 16px;
    }

    .role {
      font-size: 14px;
    }

    a {
      font-size: 16px;
      color: #62cea3;
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .contact-us {
    display: flex;
    margin-bottom: 52px;
    margin-top: 72px;
    @include media-breakpoint-down('sm') {
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 40px;
    }

    .heading {
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 8px;
      text-align: left;
      @include media-breakpoint-down('md') {
        font-size: 20px;
      }
      @include media-breakpoint-down('xs') {
        font-size: 16px;
      }
    }

    .contact-item {
      width: 50%;
      background-position: top right;
      background-image: url('../images/front/contact/form/contact-form-image.png');

      @include media-breakpoint-down('md') {
        background-size: cover;
      }

      @include media-breakpoint-down('sm') {
        width: 100%;
      }

      &.contact-item--image {
        @include media-breakpoint-down('sm') {
          display: none;
        }
      }
    }

    form {
      height: 100%;
      background-color: white;
      padding: 60px;
      @include media-breakpoint-down('md') {
        padding: 30px;
      }
      @include media-breakpoint-down('xs') {
        padding-left: 15px;
        padding-right: 15px;
      }

      .form-group {
        flex-direction: column;
        align-items: stretch;
        width: 100%;

        .f-inline-control {
          flex-grow: 1;

          input {
            max-width: 100% !important;
          }
        }

        .f-inline-label {
          flex: auto;
        }
      }

      label {
        font-size: 14px;
        width: auto;
      }

      a {
        color: #62cea3;
        text-decoration: underline;
      }
    }
  }

  .pr-disabled {
    padding-right: 0;
    @include media-breakpoint-down('sm') {
      padding-right: 15px;
    }
  }

  .pl-disabled {
    padding-left: 0;
    @include media-breakpoint-down('sm') {
      padding-left: 15px;
    }
  }
}
