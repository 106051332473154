@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@import './bootstrap.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import '~slick-carousel/slick/slick.css';

@import './base.scss';
@import './zoom.scss';
@import './table.scss';
@import './pagination.scss';
@import './form.scss';
@import './button.scss';
@import './toast.scss';
@import './alert.scss';
@import './print.scss';
@import './captcha.scss';
@import './auction-preview.scss';
@import './active-auction-panel.scss';
@import './hamburger-menu.scss';
@import './bids-alert-box.scss';
@import './front-hamburger-menu.scss';
@import './component-admin-user-form.scss';
@import './component-admin-auction-edit.scss';
@import './component-admin-box-content.scss';
@import './component-admin-filters-box.scss';
@import './component-admin-news.scss';
@import './component-admin-progress-form-menu.scss';
@import './component-admin-services.scss';
@import './component-admin-sidebar.scss';
@import './component-admin-map-modal.scss';
@import './component-admin-auctions-table';
@import './component-base-front-sidebar-box.scss';
@import './component-box-centered.scss';
@import './component-buyer-auction.scss';
@import './component-front-about-us.scss';
@import './component-front-auctions-list.scss';
@import './component-front-auctioneers-list.scss';
@import './component-front-banner-navigation.scss';
@import './component-front-contact.scss';
@import './component-front-i-want-to-sell';
@import './component-front-hint.scss';
@import './component-front-main-filter.scss';
@import './component-front-news.scss';
@import './component-front-tabs-menu.scss';
@import './component-front-top-menu.scss';
@import './component-header-logged-user.scss';
@import './component-invoices.scss';
@import './component-map.scss';
@import './component-modal.scss';
@import './component-tabs.scss';
@import './component-front-account-box.scss';
@import './component-front-separator.scss';
@import './component-front-auction-detail.scss';
@import './component-account-documents.scss';
@import './component-auction-process-tab.scss';
@import './component-cookie-verification.scss';
@import './component-informed-consents-modal.scss';
@import './component-password-validator.scss';
@import './layout-header.scss';
@import './layout-front-header.scss';
@import './layout-footer.scss';
@import './layout-front-footer.scss';
@import './layout-iframe.scss';
@import './layout-iframe-detail.scss';
@import './page-auth.scss';
@import './page-admin.scss';
@import './page-front.scss';
@import './page-registartion.scss';
@import './page-account.scss';
@import './page-account-personal-data.scss';
@import './page-admin-buyer.scss';
@import './page-auction-form.scss';
@import './page-account-auction-informations.scss';
@import './component-auction-404';
@import './component-auction-document-edit';
@import './component-auction-document-table';
@import './component-wysiwyg';
@import './component-front-auction-information';
@import './component-auction-ended-info';
@import './pages-tree.scss';
