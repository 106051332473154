.layout-iframe-detail {
  padding: 5px;

  .btn {
    font-size: 12px;
  }

  .more-btn {
    font-size: 12px !important;
  }

  .component-front-auction-detail {
    padding-top: 10px;

    .auction-detail-content {
      padding: 10px;
      margin-bottom: 10px;

      .auction-title {
        font-size: 13px;
      }

      .block-auction .banner-info {
        padding: 15px;

        img {
          width: 30px;
          margin-right: 20px;
        }

        .block-text {

          .item-title {
            font-size: 13px;
          }

          .item-title {
            margin-bottom: 5px;
          }
        }
      }

      .block-alert {
        margin-top: 10px;
        padding: 5px 20px;

        p {
          font-size: 13px;

          span {
            font-size: 13px;
          }
        }

        .alert-link {
          display: inline;
          float: right;
          font-size: 13px;
        }
      }

      .top-info {
        margin-top: 10px;

        .price-label {
          font-size: 13px;
        }

        .price-value {
          font-size: 15px;
        }

        .auction-label {
          font-size: 12px;
        }

        .top-info__right {
          font-size: 12px;
        }
      }

      .block-auction {
        padding-top: 10px;

        .column-images {
          flex: unset !important;
          width: calc(45vw - 20px);
          max-width: unset !important;
        }

        .image-items {
          width: calc(45vw - 20px);
        }

        .column-params {
          flex: unset !important;
          width: calc(55vw);
          max-width: unset !important;
        }
      }
    }
  }
}
