.page-account-personal-data {
  .step-buyer {
    .step-buyer-section {
      margin-top: 20px;
      padding-bottom: 10px;

      .section-title {
        margin-bottom: 40px;
        padding-bottom: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        border-bottom: 1px solid #e3e3e3;

        @include media-breakpoint-down('xs') {
          font-size: 16px;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &.invoice-address-checkbox {
        margin-top: -35px !important;
        margin-bottom: 15px;
      }

      &.invoice-address-content {
        margin-bottom: 15px;
      }

      .user-type-options {
        display: flex;
        flex-wrap: wrap;
        gap: 15px 40px;
        margin-bottom: 30px;

        .user-type-option {
          cursor: pointer;

          label {
            cursor: pointer;
          }
        }
      }

      .autoComplete_wrapper {
        width: 100%;
      }
      #user-address {
        width: 100%;
        height: 44px;
        border: 1px solid;
        border-color: #d5d5d5;
        border-radius: 30px;
        font-size: 12px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        &:focus {
          border-color: #000;
        }
      }
      #user-address-delivery {
        width: 100%;
        height: 44px;
        border: 1px solid;
        border-color: #d5d5d5;
        border-radius: 30px;
        font-size: 12px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        &:focus {
          border-color: #000;
        }
      }
      #user-address-invoice {
        width: 100%;
        height: 44px;
        border: 1px solid;
        border-color: #d5d5d5;
        border-radius: 30px;
        font-size: 12px;
        padding-left: 20px;
        padding-right: 20px;
        color: #000;
        &:focus {
          border-color: #000;
        }
      }

      .autoComplete_wrapper mark {
        color: #000;
        font-size: 14px;
        padding: 0;
      }
      .autoComplete_wrapper li {
        color: #888;
        font-size: 12px;
        padding: 6px 20px;
      }
    }
  }
}
