.auctions-page-title {
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 26px;
  margin-top: 1px;
  word-wrap: break-word;
  @include media-breakpoint-down('md') {
    text-align: center;
  }
  @include media-breakpoint-down('sm') {
    font-size: 20px;
  }

  &::first-letter {
    text-transform: capitalize;
  }
}

.component-front-auctions-list {

  .block-title {
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: 400;

    @include media-breakpoint-down('md') {
      font-size: 20px;
    }
    @include media-breakpoint-down('xs') {
      font-size: 16px;
    }
  }

  .list-items {
    .list-item {
      background: #fff;
      box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.05);
      margin: 16px 0 32px;
      padding: 15px;
      position: relative;

      .item-wrapper {
        display: flex;
        gap: 50px;
        min-height: 260px;
        position: relative;
        @include media-breakpoint-down('md') {
          gap: 30px;
        }
        @include media-breakpoint-down('sm') {
          display: block;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      .auction-tag {
        background: linear-gradient(268.2deg, #62cea3 0%, #3d8cbb 88.63%);
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        left: 16px;
        max-width: calc(100% - 70px);
        padding: 5px 18px;
        position: absolute;
        top: 18px;
        z-index: 10;
        @include media-breakpoint-down('sm') {
          font-size: 12px;
        }
      }

      .item-footer {
        align-items: center;
        background: #f9f9f9;
        display: flex;
        justify-content: center;
        margin: 15px -15px -15px;
        padding: 18px 15px;

        .footer-title {
          font-size: 16px;
          font-weight: 600;
          margin-right: 10px;

          @include media-breakpoint-down('md') {
            font-size: 14px;
            margin-bottom: 18px;
          }
        }

        .item-footer-step {
          display: flex;
          align-items: center;

          .step-index {
            display: flex;
            width: 28px;
            height: 28px;
            margin-right: 15px;
            margin-left: 30px;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            border: 2px solid #aeaeae;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 14px;
            line-height: 29px;
            color: #a4a4a4;

            @include media-breakpoint-down('md') {
              margin-left: 0;
            }
          }

          .step-text {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #969696;
          }

          &.is-active {
            .step-index {
              background: #2fa023;
              border: 2px solid #2fa023;
              color: #fff;
            }

            .step-text {
              color: #000000;
            }
          }

          @include media-breakpoint-down('md') {
            margin-bottom: 15px;
          }
        }

        @include media-breakpoint-down('md') {
          display: block;
          margin-right: -13px;
        }
      }

      .buyer-auction-action {
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 25px;

        @include media-breakpoint-down('sm') {
          right: 10px;
        }
      }

      .item-image {
        position: relative;

        .image-inner {
          position: relative;
          width: 422px;
          height: 100%;
          text-align: center;

          .item-centered {
            height: 100%;
            left: 0;
            overflow: hidden;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;

            a {
              display: flex;
              height: 100%;
            }

            &.no-image {
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                padding: 20px;
                background: #f5f5f5;
              }

              img {
                max-width: 160px;
                object-fit: contain;
                position: relative;
              }
            }

            img {
              height: 100%;
              max-width: 100%;
              object-fit: cover;
              position: absolute;
              right: 0;
              top: 0;
              width: 100%;

              @include media-breakpoint-down('sm') {
                position: relative;
                width: 100%;
                height: auto;
              }
            }

            @include media-breakpoint-down('sm') {
              position: relative;
              height: auto;
            }
          }

          @media all and (max-width: 1380px) {
            width: 300px;
          }

          @include media-breakpoint-down('sm') {
            width: 100%;
            margin-bottom: 10px;
          }
        }
      }

      .item-content {
        display: flex;
        width: 100%;
        flex-direction: column;

        .content-inner {
          height: 100%;
          padding: 10px 0;

          .item-title {
            padding-right: 60px;
            margin-bottom: 20px;
            font-size: 19px;
            font-weight: 700;
            line-height: 27px;

            @include media-breakpoint-down('md') {
              margin-bottom: 10px;
              font-size: 16px;
            }
          }

          .item-info {
            margin: 0;
            font-size: 14px;
            line-height: 29px;

            @include media-breakpoint-down('md') {
              font-size: 12px;
            }
          }
        }

        .content-middle {
          p {
            display: inline-flex;
            align-items: center;
            margin-right: 35px;
            font-size: 14px;

            img {
              margin-right: 11px;
            }

            &:last-child {
              margin-right: 0;
            }

            @include media-breakpoint-down('md') {
              font-size: 12px;
            }
          }
        }

        .content-bottom {
          display: flex;
          padding: 20px 0 7px;
          border-top: 1px solid #e3e3e3;
          align-items: center;
          justify-content: space-between;

          .item-price {
            font-weight: bold;
            font-size: 25px;
            line-height: 27px;
            color: #62cea3;

            @include media-breakpoint-down('md') {
              margin-bottom: 20px;
            }
          }

          @include media-breakpoint-down('md') {
            flex-direction: column;
            align-items: flex-start;

            .btn {
              font-size: 12px;
              padding-left: 20px !important;
              padding-right: 20px !important;
              margin-bottom: 10px;
            }
          }

          .buyer-auction-guess-price {
            .buyer-auction-guess-price-label {
              text-align: left;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              color: #000000;
            }

            .buyer-auction-guess-price-value {
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              color: #3d8cbb;

              @include media-breakpoint-down('md') {
                margin-bottom: 15px;
              }
            }
          }
        }
      }

      @include media-breakpoint-down('md') {
        padding-right: 13px;

        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  @include media-breakpoint-down('md') {
    margin-top: 0;
  }
}
