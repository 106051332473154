.component-cookie-verification {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  color: #000;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 15px 20px 0;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);

  .cookie-text {
    margin-right: auto;
    margin-bottom: 15px;

    a {
      text-decoration: underline;
    }
  }

  .cookie-button {
    font-size: 12px;
    margin-bottom: 15px;
  }
}

.component-cookies-modal {
  padding: 25px;

  label {
    text-align: left;
    cursor: pointer;
    padding-left: 5px;
    margin-left: 10px;
  }

  span {
    display: inline-block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .cookies-row {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    &.default-cookies {
      display: flex;

      .default-cookies-ico {
        width: 80px;
        margin-left: 8px;
      }

      .default-cookies-label {
        text-align: left;
      }
    }
  }
}
