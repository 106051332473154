.component-header-logged-user {
  z-index: 10;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: -15px;
  margin-right: -12px;

  .hover-show {
    display: none;
  }

  .menu-content {
    min-width: 190px;
    display: inline-flex;
    padding: 14px 22px;
    align-items: center;
    justify-content: center;
  }

  span {
    margin: 0 15px;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000;
  }

  .hover-block {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    background: white;
    box-shadow: 9px 9px 40px rgba(0, 0, 0, 0.12);

    .hover-block-content {
      padding-bottom: 17px;

      .logout {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 3px;
        padding: 9px 20px;
        font-size: 12px;
        font-weight: 400;

        img {
          margin-right: 9px;
        }
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: left;

        li {
          margin: 0;
          padding: 0;

          &.item-active {
            & > a {
              font-weight: bold;
            }
          }

          a {
            display: block;
            padding: 9px 40px 9px 20px;
            font-weight: 400;
            font-size: 12px;
            background: url('../images/front/header/menu-item.svg') right 20px center no-repeat;

            &:hover {
              background: url('../images/front/header/menu-item-active.svg') right 20px center no-repeat;
            }
          }
        }
      }
    }
  }

  &:hover {
    box-shadow: 9px 9px 40px rgba(0, 0, 0, 0.12);

    .menu-content {
      z-index: 1;
      background-color: #fff;
    }

    span {
      color: #000;
    }

    .hover-hide {
      display: none;
    }

    .hover-show {
      display: inline-flex;
    }

    .hover-block {
      max-height: 100vh;
      transition: max-height 0.5s ease-in;
    }
  }
}
