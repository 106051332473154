.component-admin-map-modal {
  // Autocomplete

  .autoComplete_wrapper ul {
    z-index: 999 !important;
  }
  &.modal-content {
    max-width: 1200px;

    .modal-item-content {
      padding: 0;

      .address-input-wrapper {
        padding: 20px;

        .form-group {
          margin-bottom: 0;

          input {
            max-width: 100% !important;
          }
        }

        .address-label {
          width: auto;
          flex: auto;
        }

        .autoComplete_wrapper {
          width: 100%;
        }
        #address-input {
          width: 100%;
          height: 44px;
          border: 1px solid;
          border-color: #d5d5d5;
          border-radius: 30px;
          font-size: 12px;
          padding-left: 20px;
          padding-right: 20px;
        }
        #address-input:focus {
          border-color: #000;
        }
        .autoComplete_wrapper mark {
          color: #000;
          font-size: 14px;
          padding: 0;
        }
        .autoComplete_wrapper li {
          color: #888;
          font-size: 12px;
        }
      }

      .map {
        width: 100%;
        cursor: default;

        svg,
        img {
          cursor: default;
        }
      }

      .button-wrapper {
        display: flex;
        padding: 20px;
        justify-content: flex-end;
      }
    }
  }
}
