.component-auction-ended-info {
  .block-title {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
  }

  .ended-info {
    font-weight: bold;
    font-size: 17px;
    color: #62cea3;
  }

  .ended-info-subtitle {
    font-size: 12px;
    font-weight: 400;
  }

  .bids-info {
    padding: 30px 0 0px 0;

    .bid-item {
      font-size: 12px;
      font-weight: 400;

      span {
        font-weight: 700;
      }
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
