.component-admin-progress-form-menu {
  ul {
    display: flex;
    list-style: none;
    gap: 11px;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      background: #eeeeee;
      font-size: 15px;
      font-weight: 500;
      margin: 0;
      position: relative;

      span {
        align-items: center;
        display: flex;
        padding: 10px 20px;

        .item-error {
          background-size: 100% auto;
          background: url('../images/error-section.svg') no-repeat;
          display: block;
          height: 16px;
          padding: 0;
          position: absolute;
          right: 8px;
          top: -8px;
          width: 16px;

          @include media-breakpoint-down('md') {
            height: 12px;
            right: 3px;
            top: 3px;
            width: 12px;
          }
        }
      }

      &.is-active {
        background: #fff;
      }

      @include media-breakpoint-down('md') {
        font-size: 14px;
        white-space: nowrap;
      }
    }

    @include media-breakpoint-down('md') {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }
}
