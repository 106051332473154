.component-admin-news {
  .item-image {
    position: relative;
    width: 166px;
    display: inline-block;
    margin: 10px 6px;
    border: 1px solid #dddddd;

    &.isDraggable {
      cursor: move;
    }

    &.isBeingDragged {
      // transform: rotate(30deg);
    }

    .image-content {
      width: 166px;
      height: 125px;
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
