.page-auction-form {

  .auction-actions {
    z-index: 10;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    height: 72px;
    background: #ffffff;
    box-shadow: 0px -15px 30px rgba(0, 0, 0, 0.1);

    @include media-breakpoint-down('md') {
      display: block;
      position: relative;
      box-shadow: none;
      height: inherit;
      margin-top: -40px;
    }

    .panel-content {
      height: 100%;

      @include media-breakpoint-down('md') {
        height: inherit;
        padding: 5px 10px;
      }

      .action-label {
        white-space: nowrap;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #000000;

        &.margin-left {
          margin-left: 35px;

          @include media-breakpoint-down('md') {
            margin-left: 0;
          }
        }
      }

      .auction-switch {
        margin-left: 13px;
      }

      .panel-left {
        display: flex;
        float: left;
        height: 100%;
        align-items: center;
        margin-left: 180px;

        @include media-breakpoint-down('md') {
          float: none;
          display: block;
          margin-left: 0;
        }

        .auction-state {
          display: flex;
          align-items: center;

          @include media-breakpoint-down('md') {
            margin: 30px 0 30px 0;
          }
        }

        .btn {
          width: 100%;
          margin-left: 22px;

          @include media-breakpoint-down('md') {
            margin-left: 0;
            margin-top: 15px;
          }
        }

        .actions-preloader {
          margin-left: 30px;

          @include media-breakpoint-down('md') {
            position: absolute;
            right: 10px;
            bottom: 10px;
          }
        }
      }

      .panel-right {
        display: flex;
        float: right;
        height: 100%;
        align-items: center;
        padding-right: 100px;

        @include media-breakpoint-down('md') {
          float: none;
          display: block;
          padding-right: 0;
        }

        a {
          display: flex;
          align-items: center;
          margin-right: 30px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #000000;

          &:last-child {
            margin-right: 0;
          }

          @include media-breakpoint-down('md') {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .auction-images {
    .item-drop {
      width: 171px;
      text-align: center;
      font-weight: bold;
      margin-right: 30px;
      border: 2px dashed #62cea3;
      color: #62cea3;
      padding: 5px 20px;
    }

    .item-button {
      position: relative;
      margin: 30px 0;
    }

    .item-help {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #000000;
    }

    .items-list {
      margin: 20px 0 30px 0;

      .no-items {
        padding: 14px 28px;
        background: #f8f8f8;
      }

      .images {
        margin-top: -10px;
        margin-left: -6px;
        margin-right: -6px;
      }

      .image-action {
        margin: 8px 0 3px 0;
        text-align: center;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #000000;
      }

      .item-image {
        position: relative;
        width: 166px;
        display: inline-block;
        margin: 10px 6px;
        border: 1px solid #dddddd;

        &.isDraggable {
          cursor: move;
        }

        &.isBeingDragged {
          // transform: rotate(30deg);
        }

        .image-content {
          width: 166px;
          height: 125px;
          background-size: auto 100%;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .video-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -35px;
          margin-left: -20px;

          svg {
            width: 40px;
          }
        }
      }
    }
  }

  .add-document {
    padding: 14px 28px;
    margin: 20px 0 30px 0;
    background: #f8f8f8;

    .item-drop {
      width: 171px;
      text-align: center;
      font-weight: bold;
      margin-right: 30px;
      border: 2px dashed #62cea3;
      color: #62cea3;
      padding: 5px 20px;
    }

    .file-preloader {
      margin: 5px 0 0 0;
      padding: 0;
    }

    .item-submit {
      float: right;
      margin-top: 2px;
    }

    .item-input {
      float: left;
      display: flex;
      align-items: center;

      @include media-breakpoint-down('sm') {
        flex-direction: column;
        align-items: flex-start;
      }

      .item-type-select {
        display: flex;
        margin-right: 20px;

        @include media-breakpoint-down('sm') {
          flex-wrap: wrap;
          margin-right: 0;
          margin-bottom: 20px;
        }

        .select-container {
          width: 100% !important;
          min-width: 220px;
          @include media-breakpoint-down('sm') {
            margin-top: 16px;
          }
        }
      }

      .item-input-wrapper {
        display: flex;
        align-items: center;

        @include media-breakpoint-down('sm') {
          margin-bottom: 16px;
          flex-wrap: wrap;
          .block-title {
            margin-bottom: 8px;
          }
        }
      }

      .block-title {
        margin-right: 19px;
        white-space: nowrap;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }

      .item-add {
        position: relative;
        margin-right: 40px;
        white-space: nowrap;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #62cea3;
        text-decoration-line: underline;
        @include media-breakpoint-down('sm') {
          margin-right: 0;
        }

        .success-ico {
          position: absolute;
          top: 3px;
          right: -22px;
        }
      }

      .item-type-select {
        display: flex;
        align-items: center;

        .select-label {
          margin-right: 16px;
          white-space: nowrap;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
        }

        .select-container {
          width: 286px;
        }
      }
    }
  }
}

.admin-auction-item-image {
  background: white;
  position: relative;
  width: 166px;
  display: inline-block;
  margin: 10px 6px;
  border: 1px solid #dddddd;
  user-select: none;

  .item-image-preview {
    opacity: 0;
    position: absolute;
    display: flex;
    top: 5px;
    right: 5px;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 100%;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover .item-image-preview {
    opacity: 1;
  }

  .image-action {
    margin: 8px 0 3px 0;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #000000;
  }

  &.isDraggable {
    cursor: move;
  }

  &.isBeingDragged {
    // transform: rotate(30deg);
  }

  .image-content {
    width: 100%;
    height: 125px;
    display: block;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .video-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -35px;
    margin-left: -20px;

    svg {
      width: 40px;
    }
  }
}
